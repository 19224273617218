import Axios from "axios";
import { showMessage } from "react-native-flash-message";
import { authHeader } from "./authHeader";
import { proxy } from "./proxy";

const axios = Axios;

export const storesApi = {
  get,
  getById,
  delete: _delete,
  update,
  saveFiles,
  b64toBlob,
  loadFile,
};

async function get(state, setState) {
  setState((s) => ({ ...s, loading: true }));
  await axios
    .post(`${proxy}/stores/all`, state.filter, {
      headers: await authHeader(),
    })
    .then((resp) => {
      setState((s) => ({
        ...s,
        count: resp.data.count,
        stores: resp.data.rows,
        filteredStores:
          s.filteredStores.length > 0
            ? resp.data.rows.filter(
                (d) => s.filteredStores.map((f) => f.id).indexOf(d.id) > -1
              )
            : resp.data.rows,
        loading: false,
      }));
    })
    .catch((error) => {
      setState((s) => ({
        ...s,
        error: "Keine Verbindung möglich!",
        loading: false,
      }));
    });
}
async function getById(state, setState, action) {
  setState((s) => ({ ...s, loading: true }));
  await axios
    .get(`${proxy}/stores/${state.store.id}`, {
      headers: await authHeader(),
    })
    .then((resp) => {
      setState((s) => ({
        ...s,
        store: { ...resp.data, submitedInputs: s.store.submitedInputs },
        loading: false,
      }));
      action && action();
    })
    .catch((error) => {
      setState((s) => ({
        ...s,
        error: "Keine Verbindung möglich!",
        loading: false,
      }));
    });
}
async function _delete(state, setState, action) {
  setState &&
    setState((s) => ({
      ...s,
      loading: true,
      // stores: s.stores.map((a) =>
      //   a.id == state.id ? { ...a, loading: true, error: "" } : a
      // ),
    }));
  await axios
    .delete(`${proxy}/stores/${state.store.id}`, {
      headers: await authHeader(),
    })
    .then((resp) => {
      setState &&
        setState((s) => ({
          ...s,
          loading: false,
          //count: s.count - 1,
          //stores: s.stores.filter((a) => a.id != state.id),
        }));
      action();
    })
    .catch((error) => {
      setState &&
        setState((s) => ({
          ...s,
          loading: false,
          error: "Keine Verbindung möglich!",
          // stores: s.stores.map((a) =>
          //   a.id == state.id
          //     ? { ...a, deleting: false, error: "Fehler beim Löschen" }
          //     : a
          // ),
        }));
    });
}
async function initialize(state, setState) {
  setState((s) => ({ ...s, loading: true }));
  console.log(">>>>>>>>", state);

  await axios
    .patch(`${proxy}/stores/${state.store.id}`, state.store, {
      headers: await authHeader(),
    })
    .then((resp) => {
      setState((s) => ({
        ...s,
        store: resp.data,
        loading: false,
      }));
    });
}

async function update(state, setState, succesAction, errorAction) {
  setState((s) => ({ ...s, loading: true }));
  //console.log(">>>>>>>>", state);
  let files = [];
  state.store.submitedInputs.forEach((submitedInput) => {
    submitedInput.files.forEach((file) => {
      files = [
        ...files,
        { id: file.id, uri: file.uri, inputId: submitedInput.inputId },
      ];
    });
  });
  //saveFiles(state, setState, succesAction, errorAction);
  await axios
    .patch(
      `${proxy}/stores/${state.store.id}`,
      {
        ...state.store,
        submitedInputs: state.store.submitedInputs.map((submitedInput) => ({
          ...submitedInput,
          files: submitedInput.files.map((file) => ({
            ...file,
            uri: "",
          })),
        })),
      },
      {
        headers: await authHeader(),
      }
    )
    .then(async (storeResp) => {
      await axios
        .get(`${proxy}/stores/${storeResp.data.id}`, {
          headers: await authHeader(),
        })
        .then((resp) => {
          resp.data.submitedInputs.forEach((si, key1) => {
            let index = 0;
            console.log("FILESSSS111", files);

            files = files.map((f, key) => {
              let result = f;
              if (
                si.files.map((i) => i.id).indexOf(f.id) < 0 &&
                f.inputId == si.inputId
              ) {
                while (
                  files.map((i) => i.id).indexOf(si.files[index].id) > -1
                ) {
                  index++;
                }
                result = {
                  ...f,
                  name: `submitedInput-${si.id}-image-${si.files[index].id}.jpg`,
                };
                index++;
              }
              return result;
            });
          });
          console.log(
            "FILESSSS",
            files.filter((im) => !im.id)
          );
          saveFiles(
            state,
            files.filter((im) => !im.id)
          ).then(async () => {
            await axios
              .get(`${proxy}/stores/${storeResp.data.id}`, {
                headers: await authHeader(),
              })
              .then((r) => {
                console.log(">>>>>>>>>>>>>>>>>>>>>", r.data);
                setState((s) => ({
                  ...s,
                  store: {
                    ...r.data,
                    submitedInputs: state.store.submitedInputs,
                  },
                  loading: false,
                }));
                showMessage({
                  message: "Gespeichert!",
                  description: "Markt erfolgreich gespeichert",
                  type: "success",
                  icon: "success",
                  floating: true,
                });
                succesAction && succesAction();
              });
          });
        });
    })
    .catch((error) => {
      setState((s) => ({
        ...s,
        error: "Keine Verbindung möglich!",
        loading: false,
      }));
      showMessage({
        message: "Aktion nicht möglich",
        description:
          error.response?.status == 401
            ? `Sie haben keine Berechtigung`
            : error.response?.status == 404
            ? "Fehler wärhend der Verbindung"
            : "Fehler wärhend der Verbindung",
        type: "warning",
        icon: "warning",
        floating: true,
        duration: 10000,
      });
      errorAction && errorAction();
    });
}
async function loadFile(url) {
  return await fetch(
    `https://reporting-app-daten.gate-internet.de/uploads/${url}`,
    {
      "Access-Control-Allow-Origin": "*",
    }
  )
    .then((response) => response.blob())
    .then((image) => image)
    .catch((err) => {
      console.error("<<<<<<", err);
      return null;
    });
}
async function saveFiles(state, files) {
  var form = [];
  const formData = new FormData();

  if (files.length) {
    for (var i = 0; i < files.length; i++) {
      formData.append(
        `pictures`,
        new File([files[i].uri], `${[files[i].name]}`, {
          type: "image/jpg",
        })
      );
      form.push(formData);
    }
    await axios
      .post(
        `${proxy}/files/upload/${state.action.id}/${state.store.id}`,
        formData,
        {
          headers: await authHeader(),
        }
      )
      .then();
  }
}

function b64toBlob(b64Data, contentType = "", sliceSize = 512) {
  console.log("!!!!!!!!!!!!!!!!!!!!!!!!", b64Data);
  const byteCharacters = atob(b64Data.slice(b64Data.indexOf(",") + 1));
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}
const getBlob = () => {
  const contentType = "image/png";
  //const b64Data = 'iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg==';

  //const blob = b64toBlob(b64Data, contentType);
  const blobUrl = URL.createObjectURL(blob);
};
