import React, { useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import Modal from "react-native-modal";
import { Icon } from "./Icon";
import { classes, getImage, theme } from "../helpers";
import { Ionicons } from "@expo/vector-icons";

export function Carousel(props) {
  const [isModalVisible, setModalVisible] = useState(false);
  const [state, setState] = useState(props.index || 0);
  console.log(">>>>>>>>>", props.files);
  console.log(">>>>>>>>>", props.index);
  const toggleModal = () => {
    //setModalVisible(!isModalVisible);
    props.setParentState((s) => ({
      ...s,
      selectedFiles: null,
      selectedIndex: null,
    }));
  };

  return (
    <View style={{ flex: 1, justifyContent: "center" }}>
      <Modal
        isVisible={props.parentState.selectedIndex >= 0}
        //onBackdropPress={toggleModal}
        //onSwipeComplete={toggleModal}
        swipeDirection="right"
        style={{ flex: 1 }}
      >
        <View
          style={[
            classes.row,
            {
              flex: 1,
              borderRadius: 10,
              width: "100%",
              position: "relative",
              justifyContent: "center",
              left: 0,
              bottom: 0,
            },
          ]}
        >
          <View
            style={{
              position: "absolute",
              zIndex: 10,
              right: 0,
              top: 0,
              padding: 10,
              justifyContent: "center",
            }}
          >
            <Icon
              onPress={toggleModal}
              tag="FontAwesome"
              name="times"
              size={25}
              color="#fff"
            />
          </View>
          <TouchableOpacity
            style={{
              position: "absolute",
              zIndex: 5,
              left: 0,
              top: 0,
              padding: 10,
              height: "100%",
              justifyContent: "center",
            }}
            onPress={() =>
              props.setParentState((s) => ({
                ...s,
                selectedIndex:
                  (((s.selectedIndex - 1) % s.selectedFiles.length) +
                    s.selectedFiles.length) %
                  s.selectedFiles.length,
              }))
            }
          >
            <Icon
              tag="Entypo"
              onPress={() =>
                props.setParentState((s) => ({
                  ...s,
                  selectedIndex:
                    (((s.selectedIndex - 1) % s.selectedFiles.length) +
                      s.selectedFiles.length) %
                    s.selectedFiles.length,
                }))
              }
              name="chevron-left"
              color="#fff"
              size={40}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() =>
              props.setParentState((s) => ({
                ...s,
                selectedIndex:
                  (((s.selectedIndex + 1) % s.selectedFiles.length) +
                    s.selectedFiles.length) %
                  s.selectedFiles.length,
              }))
            }
            style={{
              position: "absolute",
              zIndex: 5,
              right: 0,
              top: 0,
              padding: 10,
              height: "100%",
              justifyContent: "center",
            }}
          >
            <Icon
              tag="Entypo"
              name="chevron-right"
              onPress={() =>
                props.setParentState((s) => ({
                  ...s,
                  selectedIndex:
                    (((s.selectedIndex + 1) % s.selectedFiles.length) +
                      s.selectedFiles.length) %
                    s.selectedFiles.length,
                }))
              }
              color="#fff"
              size={40}
            />
          </TouchableOpacity>
          <View
            style={{
              justifyContent: "center",
              height: "100%",
              position: "absolute",
            }}
          >
            <View
              style={{
                justifyContent: "center",
                alignSelf:"center",
                //height: "100%",
              }}
            >
              <img
                style={{
                  maxWidth: "100%",
                  maxHeight: "90%",

                  //overflow: "visible",
                }}
                src={
                  props.files[state].path
                    ? getImage(
                        props.files[props.parentState.selectedIndex].path
                      )
                    : props.file[state].uri &&
                      URL.createObjectURL(props.files[state].uri)
                }
              />
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}
