import React, { useEffect, useState } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { StatusBar } from "expo-status-bar";
import FlashMessage from "react-native-flash-message";
import { Ionicons } from "@expo/vector-icons";

import {
  ADMStores,
  AllPersonals,
  ConnectionError,
  KDActions,
  KDStores,
  NewAction,
  NewPersonal,
  NewReporting,
  PLActions,
  PLStores,
  Reportings,
  SinglePersonal,
  SingleStore,
  Start,
} from "../screens";
import {
  ActivityIndicator,
  Alert,
  Button,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { classes, logout, tcheckAutentication, theme } from "../helpers";
import { Icon } from "../Utils";
import { AntDesign } from "@expo/vector-icons";

const Stack = createNativeStackNavigator();

export const Navigator = () => {
  const [state, setstate] = useState({});
  useEffect(() => {
    tcheckAutentication(state, setstate);
  }, []);

  const AktionContainer = () => (
    <Stack.Navigator
      screenOptions={{
        headerShown: true,
        headerStyle: {
          backgroundColor: theme.color.primary,
          color: "#fff",
        },
        headerTitleStyle: { color: "#fff" },
        headerTintColor: "#fff",
        headerRight: () =>
          state.isLoggedIn && (
            <TouchableOpacity
              onPress={() => logout(() => tcheckAutentication(state, setstate))}
              style={{ padding: 10 }}
            >
              <Text style={{ color: "#fff", fontWeight: "600" }}>
                Ausloggen
              </Text>
            </TouchableOpacity>
          ),
      }}
      initialRouteName={"PLActions"}
    >
      <Stack.Screen
        name="PLActions"
        children={({ route, navigation }) => (
          <PLActions
            navigation={navigation}
            route={route}
            user={state.user}
            role={state.roles[0]}
          />
        )}
        options={{
          title: "Alle Aktionen",
        }}
      />
      <Stack.Screen
        name="KDActions"
        children={({ route, navigation }) => (
          <KDActions
            route={route}
            navigation={navigation}
            user={state.user}
            role={state.roles[0]}
          />
        )}
        options={{
          title: "Aktionen",
        }}
      />
      <Stack.Screen
        name="NewAction"
        children={({ route, navigation }) => (
          <NewAction
            navigation={navigation}
            route={route}
            user={state.user}
            role={state.roles[0]}
          />
        )}
        options={{
          title: "Neu Aktion Anlegen",
          animationTypeForReplace: "push",
        }}
      />
      <Stack.Screen
        name="SingleAction"
        children={({ route, navigation }) => (
          <NewAction
            navigation={navigation}
            user={state.user}
            route={route}
            role={state.roles[0]}
          />
        )}
        options={({ route }) => ({
          headerTitle: route.params?.title
            ? () => <View>{route.params.title}</View>
            : "Aktion Übersicht",
        })}
      />
      <Stack.Screen
        name="Reportings"
        children={({ route, navigation }) => (
          <Reportings
            navigation={navigation}
            route={route}
            user={state.user}
            role={state.roles[0]}
          />
        )}
        options={{
          title: "Meine Reportings",
        }}
      />
      <Stack.Screen
        name="NewReporting"
        children={({ route, navigation }) => (
          <NewReporting
            navigation={navigation}
            route={route}
            user={state.user}
            role={state.roles[0]}
          />
        )}
        options={{
          title: "Reporting bearbeiten",
        }}
      />
      <Stack.Screen
        name="ADMStores"
        children={({ route, navigation }) => (
          <ADMStores
            navigation={navigation}
            route={route}
            user={state.user}
            role={state.roles[0]}
          />
        )}
        options={{
          title: "Meine Märkte",
        }}
      />
      <Stack.Screen
        name="PLStores"
        children={({ route, navigation }) => (
          <PLStores
            navigation={navigation}
            route={route}
            user={state.user}
            role={state.roles[0]}
          />
        )}
        options={{
          title: "Aktionsmärkte",
        }}
      />
      <Stack.Screen
        name="KDStores"
        children={({ route, navigation }) => (
          <KDStores
            navigation={navigation}
            route={route}
            user={state.user}
            role={state.roles[0]}
          />
        )}
        options={{
          title: "Märkte",
        }}
      />
      <Stack.Screen
        name="SingleStore"
        children={({ route, navigation }) => (
          <SingleStore
            navigation={navigation}
            route={route}
            user={state.user}
            role={state.roles[0]}
          />
        )}
        options={({ route }) => ({
          headerTitle: route.params?.title
            ? () => <View>{route.params.title}</View>
            : "Markt",
        })}
      />
    </Stack.Navigator>
  );

  const PersonalStack = createNativeStackNavigator();

  function Personal() {
    return (
      <PersonalStack.Navigator
        screenOptions={{
          headerShown: true,
          headerStyle: {
            backgroundColor: theme.color.primary,
            color: "#fff",
          },
          headerTitleStyle: { color: "#fff" },
          headerTintColor: "#fff",
          headerRight: () =>
            state.isLoggedIn && (
              <TouchableOpacity
                onPress={() =>
                  logout(() => tcheckAutentication(state, setstate))
                }
                style={{ padding: 10 }}
              >
                <Text style={{ color: "#fff", fontWeight: "600" }}>
                  Ausloggen
                </Text>
              </TouchableOpacity>
            ),
        }}
      >
        <PersonalStack.Screen
          name="AllPersonals"
          children={({ route, navigation }) => (
            <AllPersonals
              navigation={navigation}
              route={route}
              user={state.user}
              role={state.roles[0]}
            />
          )}
          options={{
            title: "Alle Benutzer",
          }}
        />
        <PersonalStack.Screen
          name="singlePersonal"
          children={({ route, navigation }) => (
            <SinglePersonal
              navigation={navigation}
              route={route}
              user={state.user}
              role={state.roles[0]}
            />
          )}
          options={({ route }) => ({
            headerTitle: route.params?.title
              ? route.params.title
              : "Benutzer verwalten",
          })}
        />
        <PersonalStack.Screen
          name="NewPersonal"
          children={({ route, navigation }) => (
            <NewPersonal
              navigation={navigation}
              route={route}
              user={state.user}
              role={state.roles[0]}
            />
          )}
          options={{
            title: "Neuer Benutzer",
          }}
        />
      </PersonalStack.Navigator>
    );
  }

  const Tab = createBottomTabNavigator();

  const TabNavigator = () => {
    return (
      <Tab.Navigator
        screenOptions={({ route }) => ({
          headerShown: false,
          tabBarIcon: ({ focused, color, size }) => {
            let iconName;

            if (route.name === "Home") {
              iconName = focused ? "home" : "home";
            } else if (route.name === "Personal") {
              iconName = focused ? "person" : "person";
            }

            // You can return any component that you like here!
            return <Ionicons name={iconName} size={size} color={color} />;
          },
          tabBarActiveTintColor: theme.color.primary,
          tabBarInactiveTintColor: "gray",
        })}
      >
        <Tab.Screen name="Home" component={AktionContainer} />
        <Tab.Screen name="Personal" component={Personal} />
      </Tab.Navigator>
    );
  };

  return (
    <NavigationContainer>
      {state.isLoggedIn == null ? (
        <View style={[classes.container, { backgroundColor: "white" }]}>
          <ActivityIndicator size="large" color={theme.color.primary} />
        </View>
      ) : state.isLoggedIn == false ? (
        <Stack.Navigator
          initialRouteName={state.error ? "ConnectionError" : "Start"}
          screenOptions={{
            headerShown: true,
            headerStyle: {
              backgroundColor: theme.color.primary,
              color: "#fff",
            },
            headerTitleStyle: { color: "#fff" },
            headerTintColor: "#fff",
            headerRight: () =>
              state.isLoggedIn && (
                <TouchableOpacity
                  onPress={() =>
                    logout(() => tcheckAutentication(state, setstate))
                  }
                  style={{ padding: 10 }}
                >
                  <Text style={{ color: "#fff", fontWeight: "600" }}>
                    Ausloggen
                  </Text>
                </TouchableOpacity>
              ),
          }}
        >
          <Stack.Screen
            name="Start"
            children={() => (
              <Start reload={() => tcheckAutentication(state, setstate)} />
            )}
            options={{
              title: "WMF Reporting App",
            }}
          />
          <Stack.Screen
            name="ConnectionError"
            children={() => (
              <ConnectionError
                reload={() => tcheckAutentication(state, setstate)}
              />
            )}
            options={{
              title: "WMF Reporting App",
            }}
          />
        </Stack.Navigator>
      ) : state.roles[0] == 1 ? (
        <TabNavigator />
      ) : (
        <AktionContainer />
      )}

      <FlashMessage position="top" style={{ borderRadius: 0, marginTop: 90 }} />
      <StatusBar style="auto" />
    </NavigationContainer>
  );
};
