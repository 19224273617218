import React, { useEffect, useRef, useState } from "react";
import * as DocumentPicker from "expo-document-picker";
import {
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  CheckBox,
  RefreshControl,
  ActivityIndicator,
  Alert,
  Image,
} from "react-native";
import { Picker } from "@react-native-picker/picker";
import * as Animatable from "react-native-animatable";
import {
  classes,
  createAction,
  csvToObject,
  getSingleActions,
  theme,
  updateAction,
} from "../helpers";
import { DatePickerCostume, Icon, ModalCostum } from "../Utils";
import { showMessage } from "react-native-flash-message";

export const NewAction = ({ route, navigation }) => {
  const { action } = route.params || { action: {} };
  const [state, setState] = useState({
    active: true,
    name: "",
    description: "",
    startDate: new Date(),
    endDate: new Date(),
    stores: [],
    nameError: "",
    endDateError: "",
    startDateError: "",
    loadStore: false,
    formError: "",
    ...action,
    inputs: action?.inputs
      ? action.inputs.map((i) => ({ ...i, saved: true }))
      : [],
  });
  useEffect(() => {
    if (!action) getSingleActions(state, setState);
  }, []);
  const changeEventListener = (value, name) => {
    setState({ ...state, [name]: value, [`${name}Error`]: "" });
  };
  const changeEventListenerinputs = (value, index) => {
    setState((s) => ({
      ...s,
      formError: "",
      inputs: s.inputs.map((input, i) =>
        i == index
          ? { ...input, ...value, [`${index}Error`]: "", errorMessage: null }
          : input
      ),
    }));
  };
  function toDateInputValue(date) {
    var local = new Date(date);
    local.setMinutes(local.getMinutes() - local.getTimezoneOffset());
    return local.toJSON()?.slice(0, 10) || "";
  }
  const addInput = (type) => {
    if (validateSaved(state.inputs)) {
      setState((s) => ({
        ...s,
        active: true,
        inputs: [
          ...s.inputs,
          {
            type: type,
            label: "",
            name: "",
            placeholder: "",
            items: type == "Dropdown" ? [{ value: "" }] : [],
            description: "",
            required: false,
            errorMessage: null,
          },
        ],
      }));
    }
  };
  const removeInput = (index) => {
    setState((s) => ({
      ...s,
      inputs: s.inputs.filter((input, i) => i != index),
    }));
  };

  const addDropdownItem = (index) => {
    setState((s) => ({
      ...s,
      inputs: s.inputs.map((input, i) =>
        index == i
          ? {
              ...input,
              items: [...input.items, { value: "" }],
              errorMessage: null,
            }
          : input
      ),
    }));
  };
  const saveInput = (index) => {
    if (validateInput(index)) {
      setState((s) => ({
        ...s,
        inputs: s.inputs.map((input, i) =>
          index == i ? { ...input, saved: true, errorMessage: null } : input
        ),
      }));
    }
  };
  const editInput = (index) => {
    setState((s) => ({
      ...s,
      inputs: s.inputs.map((input, i) =>
        index == i ? { ...input, saved: false, errorMessage: null } : input
      ),
    }));
  };
  const changeEventListenerMenuItem = (value, index, itemIndex) => {
    setState((s) => ({
      ...s,
      inputs: s.inputs.map((input, i) =>
        i == index
          ? {
              ...input,
              items: input.items.map((item, iI) =>
                iI == itemIndex ? { ...item, value: value } : item
              ),
              errorMessage: null,
            }
          : input
      ),
    }));
  };
  const removeItem = (index, itemIndex) => {
    setState((s) => ({
      ...s,
      inputs: s.inputs.map((input, i) =>
        i == index
          ? {
              ...input,
              items: input.items.filter((item, iI) => iI != itemIndex),
            }
          : input
      ),
    }));
  };
  const validateInput = (index) => {
    let result = true;
    const input = state.inputs[index];
    Object.entries(input).forEach((entry) => {
      const [key, value] = entry;
      switch (input.type) {
        case "Text":
          if (key == "label" || key == "placeholder") {
            if (!value) {
              result = false;
              inputsError(index);
            }
          }
          break;
        case "Uploader":
          if (key == "label" || key == "description") {
            if (!value) {
              result = false;
              inputsError(index);
            }
          }
          break;
        case "Dropdown":
          if (key == "label") {
            if (!value) {
              result = false;
              inputsError(index);
            }
          }
          if (key == "items") {
            if (
              value.length == 0 ||
              value.map((i) => i.value).indexOf("") > -1
            ) {
              result = false;
              inputsError(index);
            }
          }
          break;
        case "Checkbox":
          if (key == "label" || key == "description") {
            if (!value) {
              result = false;
              inputsError(index);
            }
          }
          break;

        default:
          break;
      }
    });

    return result;
  };
  const validateinputs = (inputs, save) => {
    let result = true;

    if (save) {
      if (state.name.trim() == "") {
        result = false;
        setState((s) => ({ ...s, nameError: "Pflichtfeld" }));
      }
      if (!state.startDate) {
        result = false;
        setState((s) => ({ ...s, startDateError: "Pflichtfeld" }));
      }
      if (!state.endDate) {
        result = false;
        setState((s) => ({ ...s, endDateError: "Pflichtfeld" }));
      }
      if (state.inputs.length == 0) {
        result = false;
        setState((s) => ({
          ...s,
          formError: "Mindestens eine Abfrage erforderlich",
        }));
      }
      if (state.stores.length == 0) {
        result = false;
        setState((s) => ({
          ...s,
          storeError: "Die Mäkte müssen importiert werden",
        }));
      }
    }
    inputs.forEach((input, index) => {
      if (!validateInput(index)) {
        result = false;
      }
    });

    return result;
  };
  const inputsError = (ind) => {
    setState((s) => ({
      ...s,
      inputs: s.inputs.map((input, i) =>
        i == ind
          ? {
              ...input,
              errorMessage: "Pflichtfelder erforderlich",
            }
          : input
      ),
    }));
  };
  const validateSaved = (inputs) => {
    let result = true;
    inputs.map((input, index) => {
      if (!input.saved) {
        result = false;
        saveError(index);
      }
    });
    return result;
  };
  const saveError = (ind) => {
    setState((s) => ({
      ...s,
      inputs: s.inputs.map((input, i) =>
        i == ind
          ? {
              ...input,
              savedErrorMessage: "Speichern erforderlich",
            }
          : input
      ),
    }));
  };

  const saveAction = () => {
    if (validateinputs(state.inputs, true) && validateSaved(state.inputs)) {
      state.id
        ? updateAction(state, setState, () => {
            navigation.navigate("PLActions");
          })
        : createAction(state, setState, () => {
            navigation.navigate("PLActions");
          });
    }
  };
  const validate = () => {
    const result =
      state.name != "" && state.endDate != "" && state.startDate != "";
    if (result == false) {
      setState((s) => ({
        ...s,
        endDateError: s.endDate == "" ? "End Datum erforderlich" : "",
        nameError: s.name == "" ? "Name erforderlich" : "",
        startDateError: s.startDate == "" ? "Start Datum erforderlich" : "",
      }));
    }
    return result;
  };

  async function saveStores() {
    setState((s) => ({ ...s, loadStore: true }));
    const file = await filepickup();

    if (file.type == "cancel" || !file.type) {
      setState((s) => ({
        ...s,
        loadStore: false,
      }));
      return;
    }
    setState((s) => ({ ...s, uri: file.uri }));
    if (file?.name.indexOf("csv") > -1) {
      const action = (results) =>
        setState((s) => ({
          ...s,
          stores: results
            ? results.map((r) => (s.id ? { ...r, actionId: s.id } : r))
            : s.stores,
          loadStore: false,
        }));
      csvToObject(file.file, action);
    } else {
      showMessage({
        message: "Upload nicht möglich",
        description: "Fehler wärhend des Uploads",
        type: "warning",
        icon: "warning",
        floating: true,
        duration: 10000,
      });
      setState((s) => ({
        ...s,
        storeError: "Formatierungsfehler! Endung .csv ist erwartet",
        loadStore: false,
      }));
    }
  }
  //console.log(state.stores);
  async function filepickup() {
    try {
      return await DocumentPicker.getDocumentAsync({
        type: "text/*",
        copyToCacheDirectory: false,
        //multiple: true,
      });
    } catch (err) {
      showMessage({
        message: "Upload nicht möglich",
        description: "Fehler wärhend des Uploads",
        type: "warning",
        icon: "warning",
        floating: true,
        duration: 10000,
      });
      setState((s) => ({
        ...s,
        storeError: "Fehler beim Hochladen",
        loadStore: false,
      }));
      return;
    }
  }
  console.log(state.startDate);
  const billingNameInput = useRef(null);
  return (
    <View style={{ flex: 1 }}>
      <ScrollView
        style={{ position: "relative" }}
        refreshControl={
          <RefreshControl
            refreshing={state.loading}
            onRefresh={() => getSingleActions(state, setState)}
          />
        }
      >
        <View style={[classes.screen, { maxWidth: 700 }]}>
          <Animatable.View
            easing="ease-in-out"
            duration={500}
            animation="fadeIn"
          >
            <Text style={classes.title3}>Basisdaten</Text>
            <View style={[classes.card, { marginTop: 10 }]}>
              <View>
                <Text>Name der Aktion*</Text>
                {state.nameError !== "" && (
                  <Text style={[classes.error]}>{state.nameError}</Text>
                )}
                <TextInput
                  ref={billingNameInput}
                  placeholder="name *"
                  textContentType="name"
                  value={state.name}
                  name="name"
                  onChangeText={(e) => changeEventListener(e, "name")}
                  style={classes.textInput}
                />
              </View>
              <View>
                <Text>Beschreibung (optional)</Text>
                <TextInput
                  multiline
                  numberOfLines={4}
                  placeholder="Beschreibung"
                  textContentType="name"
                  value={state.description}
                  name="description"
                  onChangeText={(e) => changeEventListener(e, "description")}
                  style={[classes.textInput, { height: "auto" }]}
                />
              </View>
              <View>
                <Text>Start *</Text>
                <Text style={classes.error}>{state.startDateError}</Text>
                <input
                  type="date"
                  name="startDate"
                  value={toDateInputValue(state.startDate)}
                  style={{
                    height: 30,
                    marginTop: 10,
                    marginBottom: 10,
                    borderColor: "#ccc",
                    borderStyle: "solid",
                    borderWidth: 1,
                  }}
                  onChange={(e) => {
                    if (
                      state.endDate &&
                      new Date(e.target.value).getTime() >
                        new Date(state.endDate).getTime()
                    )
                      setState((s) => ({
                        ...s,
                        startDateError:
                          "Enddatum muss nach dem Startermin sein",
                      }));
                    else changeEventListener(e.target.value, "startDate");
                  }}
                />
              </View>

              <View style={{ marginBottom: 10 }}>
                <Text>Ende *</Text>
                {state.endDateError !== "" && (
                  <Text style={classes.error}>{state.endDateError}</Text>
                )}
                <input
                  type="date"
                  name="endDate"
                  style={{
                    height: 30,
                    marginTop: 10,
                    marginBottom: 10,
                    borderColor: "#ccc",
                    borderStyle: "solid",
                    borderWidth: 1,
                  }}
                  value={toDateInputValue(state.endDate)}
                  onChange={(e) => {
                    if (
                      state.startDate &&
                      new Date(state.startDate).getTime() >
                        new Date(e.target.value).getTime()
                    )
                      setState((s) => ({
                        ...s,
                        endDateError: "Enddatum muss nach dem Startermin sein",
                      }));
                    else changeEventListener(e.target.value, "endDate");
                  }}
                />
              </View>

              <Text>Märkte importieren *</Text>
              {state.storeError != "" ? (
                <Text style={[classes.error]}>{state.storeError}</Text>
              ) : (
                {}
              )}
              <View style={[classes.row, { marginTop: 10 }]}>
                <TouchableOpacity
                  style={[
                    classes.cardGray,
                    {
                      padding: 5,
                      backgroundColor:
                        state.stores.length > 0 ? theme.color.succes : "#888",
                    },
                  ]}
                  onPress={saveStores}
                >
                  <View style={classes.row}>
                    {
                      <View style={{ marginRight: 5, marginLeft: 5 }}>
                        <Icon
                          tag="AntDesign"
                          name="addfile"
                          size={24}
                          color="#fff"
                          onPress={saveStores}
                        />
                      </View>
                    }
                    <View
                      style={[
                        classes.row,
                        { paddingLeft: 5, paddingTop: 5, paddingBottom: 5 },
                      ]}
                    >
                      <Text style={{ color: "white", fontWeight: "500" }}>
                        {state.stores?.length == 0
                          ? "Bitte Märkte als CSV hochladen"
                          : `${state.stores.length} hochgeladene Märkte`}
                      </Text>
                      {state.loadStore == true && (
                        <ActivityIndicator
                          style={{ alignSelf: "center", marginLeft: 5 }}
                          size="small"
                          color="#fff"
                        />
                      )}
                    </View>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </Animatable.View>
          <View style={{ marginTop: 30 }}>
            <Text style={classes.title3}>Abfrage</Text>
            {state.formError != "" && (
              <Text style={[classes.error]}>{state.formError}</Text>
            )}
            <View style={{ marginTop: 10 }}>
              {state.inputs.map((item, index) => {
                return item.type == "Checkbox" ? (
                  item.saved ? (
                    <View
                      key={`${item.toString()}${index}`}
                      style={[classes.card, { marginBottom: "5%" }]}
                    >
                      <Text>{item.label}</Text>
                      <View
                        style={[classes.checkboxContainer, { marginTop: 5 }]}
                      >
                        <CheckBox
                          value={false}
                          style={classes.checkbox}
                          onValueChange={null}
                        />
                        <Text style={classes.label}>{item.description}</Text>
                      </View>
                      <View style={[classes.row, { alignSelf: "flex-end" }]}>
                        {item.errorMessage ? (
                          <View
                            style={{ alignSelf: "center", marginRight: 20 }}
                          >
                            <Text style={classes.error}>
                              {item.errorMessage}
                            </Text>
                          </View>
                        ) : (
                          item.savedErrorMessage && (
                            <View
                              style={{ alignSelf: "center", marginRight: 20 }}
                            >
                              <Text style={classes.error}>
                                {item.savedErrorMessage}
                              </Text>
                            </View>
                          )
                        )}
                        <Icon
                          onPress={() => editInput(index)}
                          tag="MaterialIcons"
                          name="edit"
                          size={24}
                          color={theme.color.gray}
                          style={{ marginRight: 10 }}
                        />
                        <Icon
                          onPress={() => {
                            let conf = confirm(
                              "Wollen Sie wirklich diese Abfrage löchen?"
                            );
                            if (conf) removeInput(index);
                          }}
                          tag="MaterialIcons"
                          name="delete"
                          size={24}
                          color={theme.color.gray}
                        />
                      </View>
                    </View>
                  ) : (
                    <View
                      style={[classes.card, { marginBottom: "5%" }]}
                      key={`${item.toString()}${index}`}
                    >
                      <CheckBoxExample
                        label={item.label}
                        condition={item.description}
                      />
                      <View style={{ marginTop: 10 }}>
                        {/* <Text>Überschrift</Text> */}
                        <TextInput
                          ref={billingNameInput}
                          placeholder="Überschrift *"
                          textContentType="name"
                          value={item.label || ""}
                          name="label"
                          onChangeText={(e) =>
                            changeEventListenerinputs({ label: e }, index)
                          }
                          style={classes.textInput}
                          onSubmitEditing={() => {
                            // billingPasswordInput.current.focus();
                          }}
                        />
                        {/* <Text>Bedignung</Text> */}
                        <TextInput
                          ref={billingNameInput}
                          placeholder="Bedignung *"
                          textContentType="name"
                          value={item.description || ""}
                          name="description"
                          onChangeText={(e) =>
                            changeEventListenerinputs({ description: e }, index)
                          }
                          style={classes.textInput}
                          onSubmitEditing={() => {
                            // billingPasswordInput.current.focus();
                          }}
                        />
                      </View>
                      <View style={[classes.row]}>
                        {item.errorMessage ? (
                          <View>
                            <Text style={classes.error}>
                              {item.errorMessage}
                            </Text>
                          </View>
                        ) : (
                          item.savedErrorMessage && (
                            <View>
                              <Text style={classes.error}>
                                {item.savedErrorMessage}
                              </Text>
                            </View>
                          )
                        )}
                        <View
                          style={{
                            flex: 1,
                          }}
                        >
                          <View
                            style={[classes.row, { alignSelf: "flex-end" }]}
                          >
                            <Icon
                              onPress={() => saveInput(index)}
                              tag="FontAwesome"
                              name="check"
                              size={24}
                              color={theme.color.gray}
                              style={{ marginRight: 10 }}
                            />
                            <Icon
                              onPress={() => {
                                let conf = confirm(
                                  "Wollen Sie wirklich diese Abfrage löchen?"
                                );
                                if (conf) removeInput(index);
                              }}
                              tag="MaterialIcons"
                              name="delete"
                              size={24}
                              color={theme.color.gray}
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                  )
                ) : item.type == "Text" ? (
                  item.saved ? (
                    <View
                      key={`${item.toString()}${index}`}
                      style={[classes.card, { marginBottom: "5%" }]}
                    >
                      <Text>
                        {item.label} {item.required ? "*" : "(Optional)"}
                      </Text>
                      <TextInput
                        editable={false}
                        selectTextOnFocus={false}
                        placeholder={item.placeholder}
                        style={classes.textInput}
                      />
                      <View style={[classes.row, { alignSelf: "flex-end" }]}>
                        {item.errorMessage ? (
                          <View
                            style={{ alignSelf: "center", marginRight: 20 }}
                          >
                            <Text style={classes.error}>
                              {item.errorMessage}
                            </Text>
                          </View>
                        ) : (
                          item.savedErrorMessage && (
                            <View
                              style={{ alignSelf: "center", marginRight: 20 }}
                            >
                              <Text style={classes.error}>
                                {item.savedErrorMessage}
                              </Text>
                            </View>
                          )
                        )}
                        <Icon
                          onPress={() => editInput(index)}
                          tag="MaterialIcons"
                          name="edit"
                          size={24}
                          color={theme.color.gray}
                          style={{ marginRight: 10 }}
                        />
                        <Icon
                          onPress={() => {
                            let conf = confirm(
                              "Wollen Sie wirklich diese Abfrage löchen?"
                            );
                            if (conf) removeInput(index);
                          }}
                          tag="MaterialIcons"
                          name="delete"
                          size={24}
                          color={theme.color.gray}
                        />
                      </View>
                    </View>
                  ) : (
                    <View
                      key={`${item.toString()}${index}`}
                      style={[classes.card, { marginBottom: "5%" }]}
                    >
                      <TextInputExample
                        label={item.label}
                        placeholder={item.placeholder}
                        required={item.required}
                      />
                      <View style={{ marginTop: 10 }}>
                        <View style={[classes.checkboxContainer]}>
                          <CheckBox
                            style={classes.checkbox}
                            value={item.required == true}
                            onValueChange={(e) =>
                              changeEventListenerinputs({ required: e }, index)
                            }
                          />
                          <Text style={classes.label}>
                            Pflichtfeld
                          </Text>
                        </View>
                        <TextInput
                          ref={billingNameInput}
                          placeholder="Überschrift *"
                          textContentType="name"
                          value={item.label || ""}
                          name="label"
                          onChangeText={(e) =>
                            changeEventListenerinputs({ label: e }, index)
                          }
                          style={classes.textInput}
                          onSubmitEditing={() => {
                            // billingPasswordInput.current.focus();
                          }}
                        />
                        {/* <Text>Bedignung</Text> */}
                        <TextInput
                          ref={billingNameInput}
                          placeholder="Beschreibung *"
                          textContentType="name"
                          value={item.placeholder || ""}
                          name="placeholder"
                          onChangeText={(e) =>
                            changeEventListenerinputs({ placeholder: e }, index)
                          }
                          style={classes.textInput}
                          onSubmitEditing={() => {
                            // billingPasswordInput.current.focus();
                          }}
                        />
                      </View>
                      <View style={[classes.row]}>
                        {item.errorMessage ? (
                          <View>
                            <Text style={classes.error}>
                              {item.errorMessage}
                            </Text>
                          </View>
                        ) : (
                          item.savedErrorMessage && (
                            <View>
                              <Text style={classes.error}>
                                {item.savedErrorMessage}
                              </Text>
                            </View>
                          )
                        )}
                        <View
                          style={{
                            flex: 1,
                          }}
                        >
                          <View
                            style={[classes.row, { alignSelf: "flex-end" }]}
                          >
                            <Icon
                              onPress={() => saveInput(index)}
                              tag="FontAwesome"
                              name="check"
                              size={24}
                              color={theme.color.gray}
                              style={{ marginRight: 10 }}
                            />
                            <Icon
                              onPress={() => {
                                let conf = confirm(
                                  "Wollen Sie wirklich diese Abfrage löchen?"
                                );
                                if (conf) removeInput(index);
                              }}
                              tag="MaterialIcons"
                              name="delete"
                              size={24}
                              color={theme.color.gray}
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                  )
                ) : item.type == "Dropdown" ? (
                  item.saved ? (
                    <View
                      key={`${item.toString()}${index}`}
                      style={[classes.card, { marginBottom: "5%" }]}
                    >
                      <Text>
                        {item.label} {item.required ? "*" : "(Optional)"}
                      </Text>
                      <View style={classes.textInput}>
                        <Picker
                        //selectedValue={''}
                        //onValueChange={(itemValue, itemIndex) => setSelectedValue(itemValue)}
                        >
                          <Picker.Item label="Bitte wählen..." />
                          {item.items &&
                            item.items.map((item, index) => (
                              <Picker.Item
                                label={item.value}
                                key={`${index.toString()}${index}`}
                                value={item.value}
                              />
                            ))}
                        </Picker>
                      </View>
                      <View style={[classes.row, { alignSelf: "flex-end" }]}>
                        {item.errorMessage ? (
                          <View
                            style={{ alignSelf: "center", marginRight: 20 }}
                          >
                            <Text style={classes.error}>
                              {item.errorMessage}
                            </Text>
                          </View>
                        ) : (
                          item.savedErrorMessage && (
                            <View
                              style={{ alignSelf: "center", marginRight: 20 }}
                            >
                              <Text style={classes.error}>
                                {item.savedErrorMessage}
                              </Text>
                            </View>
                          )
                        )}
                        <Icon
                          onPress={() => editInput(index)}
                          tag="MaterialIcons"
                          name="edit"
                          size={24}
                          color={theme.color.gray}
                          style={{ marginRight: 10 }}
                        />
                        <Icon
                          onPress={() => {
                            let conf = confirm(
                              "Wollen Sie wirklich diese Abfrage löchen?"
                            );
                            if (conf) removeInput(index);
                          }}
                          tag="MaterialIcons"
                          name="delete"
                          size={24}
                          color={theme.color.gray}
                        />
                      </View>
                    </View>
                  ) : (
                    <View
                      key={`${item.toString()}${index}`}
                      style={[classes.card, { marginBottom: "5%" }]}
                    >
                      <DropdownExample
                        label={item.label}
                        items={item.items || []}
                        required={item.required}
                      />
                      <View style={{ marginTop: 10 }}>
                        <View style={[classes.checkboxContainer]}>
                          <CheckBox
                            style={classes.checkbox}
                            value={item.required == true}
                            onValueChange={(e) =>
                              changeEventListenerinputs({ required: e }, index)
                            }
                          />
                          <Text style={classes.label}>
                            Pflichtfeld
                          </Text>
                        </View>
                        <TextInput
                          ref={billingNameInput}
                          placeholder="Überschrift *"
                          textContentType="name"
                          value={item.label || ""}
                          name="label"
                          onChangeText={(e) =>
                            changeEventListenerinputs({ label: e }, index)
                          }
                          style={classes.textInput}
                          onSubmitEditing={() => {
                            // billingPasswordInput.current.focus();
                          }}
                        />

                        <Text>Dropdown Elemente *</Text>
                        <View
                          style={{
                            marginTop: 10,
                            padding: 10,
                            borderWidth: 1,
                            borderColor: "#ddd",
                          }}
                        >
                          {item.items.map((item, iIndex) => (
                            <View
                              key={`${index.toString()}${iIndex}`}
                              style={[classes.row, { position: "relative" }]}
                            >
                              <TextInput
                                ref={billingNameInput}
                                placeholder="Dropdown Element *"
                                textContentType="name"
                                value={item.value}
                                name="Dropdown Element"
                                onChangeText={(e) =>
                                  changeEventListenerMenuItem(e, index, iIndex)
                                }
                                style={[
                                  classes.textInput,
                                  { paddingRight: 40 },
                                ]}
                                onSubmitEditing={() => {
                                  // billingPasswordInput.current.focus();
                                }}
                              />
                              <View
                                style={{
                                  position: "absolute",
                                  right: 5,
                                  alignSelf: "center",
                                }}
                              >
                                <Icon
                                  onPress={() => {
                                    let conf = confirm(
                                      "Wollen Sie wirklich diese Abfrage löchen?"
                                    );
                                    if (conf) removeInput(index);
                                  }}
                                  tag="MaterialIcons"
                                  name="delete"
                                  size={24}
                                  color={theme.color.gray}
                                />
                              </View>
                            </View>
                          ))}
                        </View>
                        <TouchableOpacity
                          style={[classes.cardGray, { padding: 5 }]}
                          onPress={() => addDropdownItem(index)}
                        >
                          <View style={classes.row}>
                            <View style={{ marginRight: 5 }}>
                              <Icon
                                tag="Entypo"
                                name="plus"
                                size={25}
                                color="#fff"
                                onPress={() => addDropdownItem(index)}
                              />
                            </View>
                            <View style={{ paddingTop: 5, paddingBottom: 5 }}>
                              <Text
                                style={{ color: "white", fontWeight: "500" }}
                              >
                                Add Dropdown Element
                              </Text>
                            </View>
                          </View>
                        </TouchableOpacity>
                      </View>
                      <View style={[classes.row, { marginTop: 10 }]}>
                        {item.errorMessage ? (
                          <View>
                            <Text style={classes.error}>
                              {item.errorMessage}
                            </Text>
                          </View>
                        ) : (
                          item.savedErrorMessage && (
                            <View>
                              <Text style={classes.error}>
                                {item.savedErrorMessage}
                              </Text>
                            </View>
                          )
                        )}
                        <View
                          style={{
                            flex: 1,
                          }}
                        >
                          <View
                            style={[classes.row, { alignSelf: "flex-end" }]}
                          >
                            <Icon
                              onPress={() => saveInput(index)}
                              tag="FontAwesome"
                              name="check"
                              size={24}
                              color={theme.color.gray}
                              style={{ marginRight: 10 }}
                            />
                            <Icon
                              onPress={() => {
                                let conf = confirm(
                                  "Wollen Sie wirklich diese Abfrage löchen?"
                                );
                                if (conf) removeInput(index);
                              }}
                              tag="MaterialIcons"
                              name="delete"
                              size={24}
                              color={theme.color.gray}
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                  )
                ) : item.type == "Uploader" ? (
                  item.saved ? (
                    <View
                      key={`${item.toString()}${index}`}
                      style={[classes.card, { marginBottom: "5%" }]}
                    >
                      <Text>
                        {item.label} {item.required ? "*" : "(Optional)"}
                      </Text>
                      {item.description?.length > 0 && (
                        <Text style={{ color: "gray" }}>
                          {item.description == "image"
                            ? "Nur Bilder"
                            : item.description == "pdf"
                            ? "Nur PDFs"
                            : item.description == "video" && "Nur Videos"}
                        </Text>
                      )}
                      <View>
                        <View
                          style={{
                            backgroundColor:
                              item.description?.length > 0
                                ? item.description == "image"
                                  ? "#7ad67320"
                                  : item.description == "pdf"
                                  ? "#ea606020"
                                  : item.description == "video" && "#efc05320"
                                : "#888",
                            padding: 5,
                            marginTop: 10,
                            width: 80,
                          }}
                        >
                          <Icon
                            tag="MaterialCommunityIcons"
                            size={50}
                            name={
                              item.description?.length > 0
                                ? item.description == "image"
                                  ? "file-image"
                                  : item.description == "pdf"
                                  ? "file-pdf"
                                  : item.description == "video" && "file-video"
                                : "file"
                            }
                            color={
                              item.description?.length > 0
                                ? item.description == "image"
                                  ? "#7ad673"
                                  : item.description == "pdf"
                                  ? "#ea6060"
                                  : item.description == "video" && "#efc053"
                                : "white"
                            }
                          />
                        </View>
                      </View>
                      <View style={[classes.row, { alignSelf: "flex-end" }]}>
                        {item.errorMessage ? (
                          <View
                            style={{ alignSelf: "center", marginRight: 20 }}
                          >
                            <Text style={classes.error}>
                              {item.errorMessage}
                            </Text>
                          </View>
                        ) : (
                          item.savedErrorMessage && (
                            <View
                              style={{ alignSelf: "center", marginRight: 20 }}
                            >
                              <Text style={classes.error}>
                                {item.savedErrorMessage}
                              </Text>
                            </View>
                          )
                        )}
                        <Icon
                          onPress={() => editInput(index)}
                          tag="MaterialIcons"
                          name="edit"
                          size={24}
                          color={theme.color.gray}
                          style={{ marginRight: 10 }}
                        />
                        <Icon
                          onPress={() => {
                            let conf = confirm(
                              "Wollen Sie wirklich diese Abfrage löchen?"
                            );
                            if (conf) removeInput(index);
                          }}
                          tag="MaterialIcons"
                          name="delete"
                          size={24}
                          color={theme.color.gray}
                        />
                      </View>
                    </View>
                  ) : (
                    <View
                      key={`${item.toString()}${index}`}
                      style={[classes.card, { marginBottom: "5%" }]}
                    >
                      <UploaderExample
                        label={item.label}
                        required={item.required}
                        description={item.description}
                      />
                      <View style={{ marginTop: 10 }}>
                        <View style={[classes.checkboxContainer]}>
                          <CheckBox
                            style={classes.checkbox}
                            value={item.required == true}
                            onValueChange={(e) =>
                              changeEventListenerinputs({ required: e }, index)
                            }
                          />
                          <Text style={classes.label}>
                            Pflichtfeld
                          </Text>
                        </View>
                        <TextInput
                          ref={billingNameInput}
                          placeholder="Überschrift *"
                          textContentType="name"
                          value={item.label || ""}
                          name="label"
                          onChangeText={(e) =>
                            changeEventListenerinputs({ label: e }, index)
                          }
                          style={classes.textInput}
                          onSubmitEditing={() => {
                            // billingPasswordInput.current.focus();
                          }}
                        />
                        <View style={classes.textInput}>
                          <Picker
                            selectedValue={item.description || ""}
                            onValueChange={(itemValue, itemIndex) =>
                              changeEventListenerinputs(
                                { description: itemValue },
                                index
                              )
                            }
                          >
                            <Picker.Item
                              style={{ color: "gray" }}
                              label="Dateitype auswählen... *"
                            />
                            {[
                              { name: "Bilder", value: "image" },
                              { name: "PDFs", value: "pdf" },
                              // { name: "Videos", value: "video" },
                            ].map((item1, index1) => (
                              <Picker.Item
                                label={item1.name}
                                key={`${index.toString()}${index1}`}
                                value={item1.value}
                              />
                            ))}
                          </Picker>
                        </View>
                      </View>
                      <View style={[classes.row]}>
                        {item.errorMessage ? (
                          <View>
                            <Text style={classes.error}>
                              {item.errorMessage}
                            </Text>
                          </View>
                        ) : (
                          item.savedErrorMessage && (
                            <View>
                              <Text style={classes.error}>
                                {item.savedErrorMessage}
                              </Text>
                            </View>
                          )
                        )}
                        <View
                          style={{
                            flex: 1,
                          }}
                        >
                          <View
                            style={[classes.row, { alignSelf: "flex-end" }]}
                          >
                            <Icon
                              onPress={() => saveInput(index)}
                              tag="FontAwesome"
                              name="check"
                              size={24}
                              color={theme.color.gray}
                              style={{ marginRight: 10 }}
                            />
                            <Icon
                              onPress={() => {
                                let conf = confirm(
                                  "Wollen Sie wirklich diese Abfrage löchen?"
                                );
                                if (conf) removeInput(index);
                              }}
                              tag="MaterialIcons"
                              name="delete"
                              size={24}
                              color={theme.color.gray}
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                  )
                ) : (
                  <View
                    key={`${item.toString()}${index}`}
                    style={{ marginBottom: 10 }}
                  >
                    <Text>Überschrift Feld 1</Text>
                  </View>
                );
              })}
            </View>
            <View>
              <ModalCostum
                action={
                  <View style={[classes.cardGray, { padding: 5 }]}>
                    <View style={classes.row}>
                      <View style={{ marginRight: 5 }}>
                        <Icon
                          tag="Entypo"
                          name="plus"
                          size={25}
                          color="#fff"
                          //onPress={() => navigation.navigate("NewAction")}
                        />
                      </View>
                      <View style={{ paddingTop: 5, paddingBottom: 5 }}>
                        <Text style={{ color: "white", fontWeight: "500" }}>
                          Feld hinzufügen
                        </Text>
                      </View>
                    </View>
                  </View>
                }
                inputTypes={inputTypes}
                addInput={addInput}
                validateinputs={() =>
                  validateinputs(state.inputs) && validateSaved(state.inputs)
                }
              />
            </View>
          </View>
        </View>
      </ScrollView>

      <View style={[classes.row]}>
        <View
          style={{
            width: "50%",
            paddingHorizontal: 10,
          }}
        >
          <TouchableOpacity
            onPress={() => navigation.goBack()}
            style={[classes.buttonS]}
          >
            <Text style={{ color: "#fff", alignSelf: "center" }}>
              ABBRECHEN
            </Text>
          </TouchableOpacity>
        </View>
        <View
          style={{
            width: "50%",
            paddingHorizontal: 10,
          }}
        >
          <TouchableOpacity onPress={saveAction} style={classes.button}>
            <Text style={{ color: "#fff", alignSelf: "center" }}>
              SPEICHERN
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};
const inputTypes = ["Text", "Uploader", "Checkbox", "Dropdown"];
const CheckBoxExample = ({ label, condition }) => (
  <View
    style={{ padding: 10, backgroundColor: theme.color.primaryTransparent }}
  >
    <Text>{label || "Überschrift"}</Text>
    <View style={[classes.checkboxContainer, { marginTop: 5 }]}>
      <CheckBox value={false} style={classes.checkbox} onValueChange={null} />
      <Text style={classes.label}>{condition || "Bedignung"}</Text>
    </View>
  </View>
);

const TextInputExample = ({ label, placeholder, required }) => (
  <View
    style={{ padding: 10, backgroundColor: theme.color.primaryTransparent }}
  >
    <Text>
      {label || "Überschrift"} {required ? "*" : "(Optional)"}
    </Text>
    <TextInput
      editable={false}
      selectTextOnFocus={false}
      placeholder={placeholder || "Beschreibung"}
      style={classes.textInput}
    />
  </View>
);
const DropdownExample = ({ label, items, required }) => (
  <View
    style={{ padding: 10, backgroundColor: theme.color.primaryTransparent }}
  >
    <Text>
      {label || "Überschrift"} {required ? "*" : "(Optional)"}
    </Text>
    <View style={classes.textInput}>
      <Picker
      //selectedValue={''}
      //onValueChange={(itemValue, itemIndex) => setSelectedValue(itemValue)}
      >
        <Picker.Item label="Bitte wählen..." />
        {items.map((item, index) => (
          <Picker.Item
            label={item.value}
            key={`${index.toString()}${index}`}
            value={item}
          />
        ))}
      </Picker>
    </View>
  </View>
);

const UploaderExample = ({ label, required, description }) => (
  <View
    style={{ padding: 10, backgroundColor: theme.color.primaryTransparent }}
  >
    <Text>
      {label || "Überschrift"} {required ? "*" : "(Optional)"}
    </Text>
    {description?.length > 0 && (
      <Text style={{ color: "gray" }}>
        {description == "image"
          ? "Nur Bilder"
          : description == "pdf"
          ? "Nur PDFs"
          : description == "video" && "Nur Videos"}
      </Text>
    )}
    <View>
      <View
        style={{
          backgroundColor:
            description?.length > 0
              ? description == "image"
                ? "#7ad67320"
                : description == "pdf"
                ? "#ea606020"
                : description == "video" && "#efc05320"
              : "#888",
          padding: 5,
          marginTop: 10,
          width: 80,
        }}
      >
        <Icon
          tag="MaterialCommunityIcons"
          size={50}
          name={
            description?.length > 0
              ? description == "image"
                ? "file-image"
                : description == "pdf"
                ? "file-pdf"
                : description == "video" && "file-video"
              : "file"
          }
          color={
            description?.length > 0
              ? description == "image"
                ? "#7ad673"
                : description == "pdf"
                ? "#ea6060"
                : description == "video" && "#efc053"
              : "white"
          }
        />
      </View>
    </View>
  </View>
);
