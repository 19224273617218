import Axios from "axios";
import { showMessage } from "react-native-flash-message";
import { storeData } from "./asyncStorage";
import { authHeader } from "./authHeader";
import { proxy } from "./proxy";

const axios = Axios;

export async function login(state, setState, succesAction, errorAction) {
  setState((s) => ({ ...s, loading: true }));
  await axios
    .post(
      `${proxy}/login`,
      {
        firstname: state.firstname,
        lastname: state.lastname,
        password: state.password,
      },
      {
        headers: await authHeader(),
      }
    )
    .then((response) => {
      storeData("authantication", response.data);
      setState((s) => ({ ...s, user: response.data, loading: false }));
      succesAction && succesAction();

      showMessage({
        message: "Hallo, " + response.data.user.firstname + "!",
        description: "Einloggen Erfolgreich",
        type: "success",
        icon: "success",
        floating: true,
      });
    })
    .catch((error) => {
      errorAction && errorAction();
      showMessage({
        message: "Verbindung nicht möglich",
        description:
          error.response?.status == 401
            ? `Falsches Passwort`
            : error.response?.status == 404
            ? "Deise Nutzer existiert nicht"
            : "Ein Fehler wärhend der Verbindung",
        type: "warning",
        icon: "warning",
        floating: true,
        duration: 10000,
      });
      setState((s) => ({ ...s, loading: false }));
    });
}

export async function signup(state, setState) {
  await axios
    .get(`${proxy}/signup`, state.selected, {
      headers: await authHeader(),
    })
    .then((resp) => {
      setState((s) => ({ ...s, appStatistics: resp.data }));
    });
}
export async function tcheckAutentication(state, setState) {
  setState({
    loading: true,
  });
  await axios
    .get(`${proxy}/private`, {
      headers: await authHeader(),
    })
    .then((resp) => {
      setState((s) => ({
        ...s,
        isLoggedIn: true,
        user: resp.data.user,
        roles: resp.data.user.roles?.map((r) => r.value),
        loading: false,
      }));
    })
    .catch((error) => {
      if (error.response?.status == 401 || error.response?.status == 500) {
        setState((s) => ({ ...s, isLoggedIn: false, loading: false }));
      } else {
        setState((s) => ({
          ...s,
          error: "Keine Verbindung möglich!",
          isLoggedIn: false,
          loading: false,
        }));
      }
    });
}

export async function createAction(state, setState, succesAction, errorAction) {
  setState((s) => ({ ...s, loading: true }));
  await axios
    .post(
      `${proxy}/actions`,
      { ...state },
      {
        headers: await authHeader(),
      }
    )
    .then((response) => {
      setState((s) => ({ ...s, ...response.data, loading: false }));
      succesAction && succesAction();

      showMessage({
        message: "Gespeichert!",
        description: "Action erfolgreich gespeichert",
        type: "success",
        icon: "success",
        floating: true,
      });
    })
    .catch((error) => {
      errorAction && errorAction();
      showMessage({
        message: "Verbindung nicht möglich",
        description:
          error.response?.status == 401
            ? `Sie haben keine Berechtigung`
            : error.response?.status == 404
            ? "Sie haben keine Berechtigung"
            : "Fehler wärhend der Verbindung",
        type: "warning",
        icon: "warning",
        floating: true,
        duration: 10000,
      });
      setState((s) => ({ ...s, loading: false }));
    });
}

export async function getActions(state, setState) {
  setState((s) => ({ ...s, loading: true }));
  await axios
    .get(`${proxy}/actions`, {
      headers: await authHeader(),
    })
    .then((resp) => {
      setState((s) => ({
        ...s,
        count: resp.data.count,
        actions:
          state.role == 3
            ? resp.data.rows.filter(
                (action) =>
                  action.stores.filter((store) => store.userId == state.user.id)
                    .length > 0
              )
            : resp.data.rows,
        loading: false,
      }));
    })
    .catch((error) => {
      setState((s) => ({
        ...s,
        error: "Keine Verbindung möglich!",
        loading: false,
      }));
    });
}
export async function getSingleActions(state, setState) {
  setState((s) => ({ ...s, loading: true }));
  await axios
    .get(`${proxy}/actions/${state.id}`, {
      headers: await authHeader(),
    })
    .then((resp) => {
      setState((s) => ({
        ...s,
        ...resp.data,
        loading: false,
      }));
    })
    .catch((error) => {
      setState((s) => ({
        ...s,
        error: "Keine Verbindung möglich!",
        loading: false,
      }));
    });
}
export async function deleteActions(state, setState) {
  setState((s) => ({
    ...s,
    actions: s.actions.map((a) =>
      a.id == state.id ? { ...a, deleting: true, error: "" } : a
    ),
  }));
  await axios
    .delete(`${proxy}/actions/${state.id}`, {
      headers: await authHeader(),
    })
    .then((resp) => {
      setState((s) => ({
        ...s,
        count: s.count - 1,
        actions: s.actions.filter((a) => a.id != state.id),
      }));
    })
    .catch((error) => {
      setState((s) => ({
        ...s,
        error: "Keine Verbindung möglich!",
        actions: s.actions.map((a) =>
          a.id == state.id
            ? { ...a, deleting: false, error: "Fehler beim Löschen" }
            : a
        ),
      }));
    });
}
export async function updateAction(state, setState, succesAction, errorAction) {
  setState((s) => ({ ...s, loading: true }));
  await axios
    .patch(`${proxy}/actions`, state, {
      headers: await authHeader(),
    })
    .then((resp) => {
      setState((s) => ({
        ...s,
        count: resp.data.count,
        actions: resp.data.rows,
        loading: false,
      }));
      showMessage({
        message: "Gespeichert!",
        description: "Action erfolgreich gespeichert",
        type: "success",
        icon: "success",
        floating: true,
      });
      succesAction && succesAction();
    })
    .catch((error) => {
      setState((s) => ({
        ...s,
        error: "Keine Verbindung möglich!",
        loading: false,
      }));
      showMessage({
        message: "Aktion nicht möglich",
        description:
          error.response?.status == 401
            ? `Sie haben keine Berechtigung`
            : error.response?.status == 404
            ? "Fehler wärhend der Verbindung"
            : "Fehler wärhend der Verbindung",
        type: "warning",
        icon: "warning",
        floating: true,
        duration: 10000,
      });
      errorAction && errorAction();
    });
}

export async function logout(reload) {
  storeData("authantication", {}).then(() => {
    reload();
  });
}
