import { registerRootComponent } from "expo";

import App from "./App";

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(App);

/*
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDwjrPZ3d4DNlzrpVleL9tNiBS1etujPNM",
  authDomain: "plusnel-reporting.firebaseapp.com",
  projectId: "plusnel-reporting",
  storageBucket: "plusnel-reporting.appspot.com",
  messagingSenderId: "436272110333",
  appId: "1:436272110333:web:e7fc9152aff8e5ad753c94",
  measurementId: "G-WHRQY7V9FZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

*/
