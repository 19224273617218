import React, { useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import Modal from "react-native-modal";
import { classes } from "../helpers";

export function ModalCostum(props) {
  const [isModalVisible, setModalVisible] = useState(false);

  const toggleModal = () => {
    if (props.validateinputs()) {
      setModalVisible(!isModalVisible);
    }
  };

  return (
    <View>
      <TouchableOpacity onPress={toggleModal}>{props.action}</TouchableOpacity>
      <Modal
        isVisible={isModalVisible}
        onBackdropPress={() => setModalVisible(false)}
        onSwipeComplete={() => setModalVisible(false)}
        swipeDirection="down"
        style={{ flex: 1 }}
      >
        <View
          style={[
            classes.card,
            {
              borderRadius: 10,
              width: "100%",
              position: "absolute",
              left: 0,
              bottom: 0,
            },
          ]}
        >
          <Text style={[classes.title3, { padding: 10 }]}>Feldtype wählen</Text>
          {props.inputTypes.map((type) => (
            <TouchableOpacity
              key={type}
              style={{
                padding: 15,
                borderBottomWidth: 1,
                borderBottom: "solid",
                borderBottomColor: "#fefefe",
              }}
              onPress={() => {
                props.addInput(type);
                setModalVisible(false);
              }}
            >
              <Text style={{fontWeight:"500"}}>{type}</Text>
            </TouchableOpacity>
          ))}
        </View>
      </Modal>
    </View>
  );
}
