import React from "react";
import { Text, TouchableHighlight, View } from "react-native";
import { classes, theme } from "../helpers";
import { Icon } from "../Utils";

export const ConnectionError = ({ reload }) => {
  return (
    <View style={classes.container}>
      <View >
        <Text style={[classes.error,{marginBottom: 40, fontWeight:"700", }]}>Verbindung nicht möglich</Text>
      </View>
      <View>
        <TouchableHighlight onPress={reload} style={{backgroundColor: "#000",padding:10, color:"#fff"}}>
          <View style={{display:"flex", justifyItems: 'center', justifyContent:"center"}}>
            <Text style={{color:'white', fontWeight:"600"}}>Aktualisieren</Text>
          </View>
        </TouchableHighlight>
      </View>
    </View>
  );
};
