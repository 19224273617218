import React, { useEffect, useRef, useState } from "react";

import {
  RefreshControl,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import {
  classes,
  storesApi,
  theme,
  usersApi,
} from "../helpers";
import { FilterModal, FloatingButton, Icon } from "../Utils";
import SkeletonContent from "react-native-skeleton-content";
import * as Animatable from "react-native-animatable";
import { TextInput } from "react-native-gesture-handler";

const wait = (timeout) => {
  return new Promise((resolve) => setTimeout(resolve, timeout));
};

export const PLStores = ({ route, navigation, user, role }) => {
  const { action } = route.params || { action: {} };
  //console.log(fs)
  const [state, setState] = useState({
    search: "",
    role: role,
    action: action,
    stores: [], // action.stores,
    filteredStores: [], //action.stores,
    //   .sort((a, b) => b.center.localeCompare(a.center))
    //   .sort((a, b) => b.status == 2)
    //   .sort((a, b) => b.status == 0)
    //   .sort((a, b) => b.status == 1),
    user: user,
    filter: {
      and:
        role == 3
          ? { actionId: action?.id, userId: user.id }
          : { actionId: action?.id },
      like: [{ name: "location", value: "" }],
    },
  });

  const onRefresh = React.useCallback(() => {
    storesApi.get(state, setState);
    //wait(2000).then(() => setRefreshing(false));
  }, []);
  const scrollRefs = useRef();

  const onFabPress = () => {
    scrollRefs.current?.scrollTo({
      y: 0,
      animated: true,
    });
  };

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      storesApi.get(state, setState);
      usersApi.get(state, setState);
    });

    return unsubscribe;
  }, [navigation]);

  //function setFilter() {}
  function setSearch(value) {
    let newState = {
      ...state,
      search: value,
      filteredStores: state.stores.filter(
        (s) =>
          s.location?.toString().toLowerCase().indexOf(value.toLowerCase()) >
            -1 ||
          s.street?.toString().toLowerCase().indexOf(value.toLowerCase()) >
            -1 ||
          s.country?.toString().toLowerCase().indexOf(value.toLowerCase()) >
            -1 ||
          s.postCode?.toString().toLowerCase().indexOf(value.toLowerCase()) >
            -1 ||
          s.filialnumber
            ?.toString()
            .toLowerCase()
            .indexOf(value.toLowerCase()) > -1 ||
          s.areaManager?.toString().toLowerCase().indexOf(value.toLowerCase()) >
            -1 ||
          s.planung?.toString().toLowerCase().indexOf(value.toLowerCase()) >
            -1 ||
          s.name?.toString().toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          s.name?.toString().toLowerCase().indexOf(value.toLowerCase()) > -1
      ),
      filter: {
        ...state.filter,
        like: state.filter.like.map((l) =>
          l.name == "location" ? { name: "location", value: value } : l
        ),
      },
    };
    setState(newState);
    // storesApi.get(newState, setState);
  }

  return (
    <View style={{ flex: 1 }}>
      <ScrollView
        ref={scrollRefs}
        refreshControl={
          <RefreshControl refreshing={state.loading} onRefresh={onRefresh} />
        }
      >
        <View style={[classes.screen, { maxWidth: 700 }]}>
          <View style={{ marginBottom: 10 }}>
            <Text style={classes.title2}>{state.action?.name}</Text>
            <Text>
              {new Date(state.action.startDate).toLocaleDateString()}
              {" - "}
              {new Date(state.action.endDate).toLocaleDateString()}
            </Text>
          </View>
          <View style={[classes.row, { marginBottom: 10 }]}>
            <View style={{ flex: 1, position: "relative" }}>
              <TextInput
                value={state.search}
                placeholder="Suchen..."
                onChangeText={setSearch}
                style={classes.textInput}
              />
              <TouchableOpacity
                style={{
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  marginTop: 10,
                  marginBottom: 10,
                  padding: 3,
                  paddingHorizontal: 11,
                  height: 35,
                  //backgroundColor: theme.color.primaryTransparent,
                }}
                onPress={() => {}}
              >
                <Icon
                  name="search"
                  tag="Ionicons"
                  size={25}
                  color={state.search ? theme.color.primary : "#ddd"}
                  style={{ alignSelf: "center" }}
                />
              </TouchableOpacity>
            </View>
            <View style={classes.row}>
              <FilterModal
                setState={setState}
                state={state}
                filters={[
                  {
                    name: "Alle Märkte",
                    value: "012",
                    colors: [
                      theme.color.succes,
                      theme.color.yellow,
                      theme.color.error,
                    ],
                    action: (store) =>
                      store.status == 0 ||
                      store.status == 1 ||
                      store.status == 2,
                  },
                  {
                    name: "Offene Märkte",
                    value: "0",
                    color: theme.color.error,
                    action: (store) => store.status == 0,
                  },
                  {
                    name: "Gespeicherte Märkte",
                    value: "1",
                    action: (store) => store.status == 1,
                    color: theme.color.yellow,
                  },
                  {
                    name: "Bestätigte Märkte",
                    value: "2",
                    color: theme.color.succes,
                    action: (store) => store.status == 2,
                  },
                ]}
                orders={[
                  {
                    name: "Offene Märkte zuerst",
                    action: (a, b) => b.status == 0,
                  },
                  {
                    name: "Gespeicherte Märkte zuerst",
                    action: (a, b) => b.status == 1,
                  },
                  {
                    name: "Bestätigte Märkte zuerst",
                    action: (a, b) => b.status == 2,
                  },
                  {
                    name: "Nach Name sortieren",
                    action: (a, b) => a.name.localeCompare(b.name),
                  },
                ]}
              />

              <TouchableOpacity
                style={{
                  padding: 5,
                  paddingLeft: 10,
                  paddingRight: 10,
                  height: 35,
                  alignSelf: "center",
                  alignContent: "center",
                }}
              >
                <Icon
                  name="export"
                  tag="Entypo"
                  size={25}
                  color="gray"
                  style={{ alignSelf: "center" }}
                />
              </TouchableOpacity>
            </View>
          </View>
          {state.filteredStores?.length > 0 ? (
            state.filteredStores.map((item, index) => (
              <View key={item.id}>
                <Animatable.View
                  easing="ease-in"
                  duration={300}
                  animation="fadeIn"
                >
                  <TouchableOpacity
                    onPress={() =>
                      navigation.navigate("SingleStore", {
                        action: state.action,
                        store: item,
                        title: (
                          <View>
                            <Text
                              numberOfLines={1}
                              ellipsizeMode="tail"
                              style={{
                                fontSize: 18,
                                fontWeight: "bold",
                                color: "#fff",
                              }}
                            >
                              {state.action.name}
                            </Text>
                            <Text
                              numberOfLines={1}
                              ellipsizeMode="tail"
                              style={{ color: "#fff" }}
                            >
                              {item.name}
                            </Text>
                          </View>
                        ),
                      })
                    }
                    style={{ marginBottom: "5%" }}
                  >
                    <View
                      style={[
                        classes.card,
                        { paddingBottom: 10, paddingTop: 10 },
                      ]}
                    >
                      <View style={classes.row}>
                        <View style={{ flex: 1 }}>
                          <Text
                            numberOfLines={2}
                            ellipsizeMode="tail"
                            style={{ fontWeight: "700" }}
                          >
                            {item.name}
                          </Text>
                          <Text numberOfLines={2} ellipsizeMode="tail">
                            {item.street}, {item.postCode} {item.location}
                          </Text>
                          <View>
                            {item.user?.id ? (
                              <Text numberOfLines={2} ellipsizeMode="tail">
                                {item.user.firstname} {item.user.lastname}
                              </Text>
                            ) : (
                              <Text style={{ color: "gray" }}>
                                Kein Personal
                              </Text>
                            )}
                          </View>
                        </View>
                        <View style={[classes.row]}>
                          {/* <View style={{justifyContent:"center"}}>
                            <Text
                              style={{
                                fontSize: 12,
                                color:
                                  item.status == 2
                                    ? theme.color.succes
                                    : item.status == 1
                                    ? theme.color.yellow
                                    : theme.color.error,
                              }}
                            >
                              {item.status == 2
                                ? "Bestätigt"
                                : item.status == 1
                                ? "Gespeichert"
                                : "Offen"}
                            </Text>
                          </View> */}
                          <View style={{ justifyContent: "center" }}>
                            <View
                              style={[
                                classes.circle,
                                {
                                  marginLeft: 10,
                                  alignSelf: "flex-end",
                                  backgroundColor:
                                    item.status == 2
                                      ? theme.color.succes
                                      : item.status == 1
                                      ? theme.color.yellow
                                      : theme.color.error,
                                },
                              ]}
                            ></View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </TouchableOpacity>
                </Animatable.View>
              </View>
            ))
          ) : (
            <SkeletonContent
              containerStyle={{ flex: 1 }}
              isLoading={true}
              layout={[
                {
                  key: "skeletonItem1",
                  width: "100%",
                  height: 77,
                  marginBottom: "5%",
                  borderRadius: 0,
                },
                {
                  key: "skeletonItem2",
                  width: "100%",
                  height: 77,
                  marginBottom: "5%",
                  borderRadius: 0,
                },
                {
                  key: "skeletonItem3",
                  width: "100%",
                  height: 77,
                  marginBottom: "5%",
                  borderRadius: 0,
                },
                {
                  key: "skeletonItem4",
                  width: "100%",
                  height: 77,
                  marginBottom: "5%",
                  borderRadius: 0,
                },
                {
                  key: "skeletonItem5",
                  width: "100%",
                  height: 77,
                  marginBottom: "5%",
                  borderRadius: 0,
                },
              ]}
            ></SkeletonContent>
          )}

          {/* <TouchableOpacity
            //onPress={() => navigation.navigate("SingleStore")}
            style={[
              { marginBottom: "5%", backgroundColor: "gray", padding: 10 },
            ]}
            activeOpacity={false}
          >
            <View style={classes.row}>
              <View style={{ marginRight: 10 }}>
                <Icon
                  tag="Entypo"
                  name="plus"
                  size={30}
                  color="#fff"
                  //onPress={() =>navigation.navigate("SingleStore")}
                />
              </View>
              <View
                style={{
                  alignSelf: "center",
                }}
              >
                <Text style={{ color: "white", fontWeight: "500" }}>
                  Neu Markt hinzufügen
                </Text>
              </View>
            </View>
          </TouchableOpacity> */}
        </View>
      </ScrollView>
      <FloatingButton onPress={onFabPress} />
    </View>
  );
};
