import Papa from "papaparse";
import * as FileSystem from "expo-file-system";
import { Alert, Linking } from "react-native";
import * as Sharing from "expo-sharing";
export async function csvToObject(uri, action) {
  // FileSystem.readAsStringAsync(csvData.uri, {})
  //   .then((r) => {
  //     console.log(r);
  //   })
  //   .catch((e) => {
  //     console.error(e);
  //   });

  // let options = { encoding: FileSystem.EncodingType.Base64 };
  // FileSystem.readAsStringAsync(csvData.uri, options)
  //   .then((data) => {
  //     const base64 = "data:image/jpg;base64" + data;
  //     console.log(base64)
  //     resolve(base64); // are you sure you want to resolve the data and not the base64 string?
  //   })
  //   .catch((err) => {
  //     console.log("​getFile -> err", err);
  //     reject(err);
  //   });

  // const tempUri = FileSystem.cacheDirectory + "temp_csv";
  // await FileSystem.copyAsync({ from: uri, to: tempUri });
  // const result = await FileSystem.readAsStringAsync(tempUri, {
  //encoding: FileSystem.EncodingType.Base64,
  // });
  // await FileSystem.deleteAsync(tempUri);
  // console.log(">>>>>>>>>>>>>>>>>>>>>>", result);
  //console.log("|||||||||||||||||||", uri);

  Papa.parse(uri, {
    encoding: "ISO-8859-1",
    complete: (result) => {
      let data = result.data;
      //console.log(">>>>>>>>>>>>>>>>>>>>>>", data);
      const attributes = result.data[0];
      data = result.data.slice(1).map((d) => {
        let res = {};
        attributes.forEach((a, i) => {
          res = { ...res, [a]: d[i] };
        });
        return res;
      });
      //console.log(">>>>>>>>>>>>>>>>>>>>>>", data);
      action(data.filter((d) => d.filialnumber));
    },
  });
}

export async function getPdf(uri, action) {
  const tempUri = FileSystem.cacheDirectory + "temp_pdf";
  await FileSystem.copyAsync({ from: uri, to: tempUri });
  const result = await FileSystem.readAsStringAsync(tempUri, {
    encoding: FileSystem.EncodingType.Base64,
  });
  await FileSystem.deleteAsync(tempUri);
  console.log("------------------", result);

  action(result);
  // Papa.parse(result, {
    //encoding: "ISO-8859-1",
  //   complete: (result) => {
  //     let data = result.data;
  //     const attributes = result.data[0];
  //     data = result.data.slice(1).map((d) => {
  //       let res = {};
  //       attributes.forEach((a, i) => {
  //         res = { ...res, [a]: d[i] };
  //       });
  //       return res;
  //     });
  //     action(data);
  //   },
  // });
}

export async function linking(url) {
  // Checking if the link is supported for links with custom URL scheme.
  const supported = await Linking.canOpenURL(url);

  if (supported) {
    // Opening the link with some app, if the URL scheme is "http" the web link should be opened
    // by some browser in the mobile
    await Linking.openURL(url);
  } else {
    //Alert.alert(`Da Datei kann nicht geöffnet werden`);
    //await Linking.openURL(url)
  }
  shareFile(url);
}

export async function downloadFileInlocal(url, setState) {
  const uri = await FileSystem.downloadAsync(
    "http://techslides.com/demos/sample-videos/small.mp4",
    FileSystem.documentDirectory + "small.mp4"
  );

  return uri;
}

export async function shareFile(uri) {
  if (!(await Sharing.isAvailableAsync())) {
    alert(`Verteilung nicht möglich`);
    return;
  }

  await Sharing.shareAsync(uri);
}

export function getImage(url) {
  return `https://reporting-app-daten.gate-internet.de/uploads/${url}`;
}
