import React, { useEffect, useRef, useState } from "react";

import {
  Alert,
  RefreshControl,
  ScrollView,
  Text,
  TouchableOpacity,
  CheckBox,
  View,
  Linking,
  Image,
  Platform,
  Button,
  ActivityIndicator,
} from "react-native";
import {
  classes,
  getImage,
  getPdf,
  linking,
  storesApi,
  theme,
  usersApi,
} from "../helpers";
import { Carousel, Icon } from "../Utils";
import SkeletonContent from "react-native-skeleton-content";
import * as Animatable from "react-native-animatable";
import { TextInput } from "react-native-gesture-handler";
import { Picker } from "@react-native-picker/picker";
import * as ImagePicker from "expo-image-picker";
import { Video, AVPlaybackStatus } from "expo-av";
import { showMessage } from "react-native-flash-message";
import * as DocumentPicker from "expo-document-picker";

export const SingleStore = ({ route, navigation, user, role }) => {
  const { action, store } = route.params || { action: {} };
  const video = useRef(null);
  const [status, setStatus] = useState({});
  const [state, setState] = useState({
    action: action,
    store: store,
    user: user,
    editUser: false,
    filter: { and: { actionId: action?.id } },
  });
  const [image, setImage] = useState(null);

  const onRefresh = React.useCallback(() => {
    storesApi.getById(state, setState);
    //wait(2000).then(() => setRefreshing(false));
  }, []);

  useEffect(() => {
    bindSubmitedIputToStore(state, setState);
    // (async () => {
    //   if (Platform.OS !== "web") {
    //     const { status } =
    //       await ImagePicker.requestMediaLibraryPermissionsAsync();
    //     if (status !== "granted") {
    //       alert("Sorry, we need camera roll permissions to make this work!");
    //     }
    //   }
    // })();
  }, []);

  const pickImage = async (type, id, option) => {
    ImagePicker.requestCameraPermissionsAsync().then(async (resp) => {
      let result = await ImagePicker["launchImageLibraryAsync"]({
        mediaTypes:
          type == "image"
            ? ImagePicker.MediaTypeOptions.Images
            : ImagePicker.MediaTypeOptions.Videos,
        allowsMultipleSelection: true,
        allowsEditing: true,
        // aspect: [4, 3],
        quality: 1,
      });

      //console.log(">>>>>>>>>>>>", result);

      if (!result.cancelled) {
        setState((s) => ({
          ...s,
          images: [
            ...(s.images ?? []),
            ...result.selected.map((r) =>
              storesApi.b64toBlob(r.uri, "image/png")
            ),
          ],
          store: {
            ...s.store,

            submitedInputs: s.store.submitedInputs.map((si) =>
              si.inputId == id
                ? {
                    ...si,
                    files: [
                      ...si?.files,
                      ...result.selected.map((r) => ({
                        //url: r.uri,
                        uri: storesApi.b64toBlob(r.uri, "image/png"),
                        type: "image",
                        description: type,
                        isPortrait: r.height > r.width,
                      })),
                    ],
                  }
                : si
            ),
          },
        }));
        //setImage(result.selected[2].uri);
      }
    });
  };

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      storesApi.getById(state, setState);
    });

    return unsubscribe;
  }, [navigation]);
  const attribute = [
    { name: "Name", value: "name" },
    { name: "Filialnummer", value: "filialnumber" },
    { name: "Land", value: "country" },
    { name: "Strasse", value: "street" },
    { name: "PLZ", value: "postCode" },
    { name: "Ort", value: "location" },
    { name: "Area_Manager", value: "areaManager" },
    { name: "Planung", value: "planning" },
    { name: "Center", value: "center" },
  ];
  const changeEventListener = (value, name) => {
    setState({ ...state, store: { ...state.store, [name]: value } });
  };
  const changeSubmitedInput = (value, inputId) => {
    setState((s) => ({
      ...s,
      store: {
        ...store,
        submitedInputs: s.store.submitedInputs.map((submitedInput) =>
          submitedInput.inputId == inputId
            ? { ...submitedInput, value: value }
            : submitedInput
        ),
      },
    }));
  };
  async function savePdf(inputId) {
    setState((s) => ({ ...s, loadStore: true }));
    const files = await filepickup();

    if (files.type == "cancel" || !files.type) {
      setState((s) => ({
        ...s,
        loadStore: false,
      }));
      return;
    }
    console.log("EEEEE", files.output[1]);
    let i = 0;
    for (i = 0; i < files.output.length; i++) {
      if (files.output[i]?.name.indexOf("pdf") > -1) {
        setState((s) => ({
          ...s,
          files: [...(s.files ?? []), files.output[i]],

          store: {
            ...s.store,
            submitedInputs: s.store.submitedInputs.map((si) =>
              si.inputId == inputId
                ? {
                    ...si,
                    files: [
                      ...si?.files,
                      {
                        name: files.output[i].name,
                        //url: files.output[i],
                        uri: files.output[i],
                        type: "pdf",
                        description: "pdf",
                      },
                    ],
                  }
                : si
            ),
          },
        }));

        //getPdf(file.uri, action);
      } else {
        showMessage({
          message: "Upload nicht möglich",
          description: "Fehler wärhend des Uploads",
          type: "warning",
          icon: "warning",
          floating: true,
          duration: 10000,
        });
        setState((s) => ({
          ...s,
          storeError: "Formatierungsfehler! Endung .pdf ist erwartet",
          loadStore: false,
        }));
      }
    }
  }
  console.log(state.store?.submitedInputs);
  console.log(state.store);
  async function filepickup() {
    try {
      return await DocumentPicker.getDocumentAsync({
        type: "application/*",
        copyToCacheDirectory: false,
        multiple: true,
      });
    } catch (err) {
      showMessage({
        message: "Upload nicht möglich",
        description: "Fehler wärhend des Uploads",
        type: "warning",
        icon: "warning",
        floating: true,
        duration: 10000,
      });
      setState((s) => ({
        ...s,
        storeError: "Fehler beim Hochladen",
        loadStore: false,
      }));
      return;
    }
  }
  //console.log(state.store.submitedInputs);

  function bindSubmitedIputToStore(state, setState) {
    let submitedInputs = state.store.submitedInputs;
    state.action.inputs.forEach((input) => {
      let inSubmitedIput = false;
      submitedInputs = submitedInputs.map((submitedInput) => {
        if (submitedInput.inputId == input.id) {
          inSubmitedIput = true;
          return {
            ...submitedInput,
            files: submitedInput.files.map((f) => ({
              ...f,
              //uri: getImage(f.path),
            })),
            storeId: state.store.id,
            description: input.description,
            label: input.label,
            name: input.name,
            placeholder: input.placeholder,
            required: input.required,
            type: input.type,
          };
        }
        return submitedInput;
      });
      if (inSubmitedIput == false) {
        submitedInputs = [
          ...submitedInputs,
          {
            storeId: state.store.id,
            inputId: input.id,
            description: input.description,
            label: input.label,
            name: input.name,
            placeholder: input.placeholder,
            required: input.required,
            type: input.type,
            value: null,
            files: [],
          },
        ];
      }
    });

    setState((s) => ({
      ...s,
      store: { ...s.store, submitedInputs: submitedInputs },
    }));
  }

  const saveFiles = (inputs) => {
    let formdata = new FormData();
    inputs.forEach((input, key) => {
      input.files.forEach((file, fileKey) => {});
    });
  };

  return (
    <View style={{ flex: 1 }}>
      <ScrollView
        refreshControl={
          <RefreshControl refreshing={state.loading} onRefresh={onRefresh} />
        }
      >
        <View style={[classes.screen, { maxWidth: 700 }]}>
          {state.store ? (
            <Animatable.View easing="ease-in" duration={500} animation="fadeIn">
              <View>
                <View>
                  <Text style={[classes.title3, { marginBottom: 10 }]}>
                    Marktdetaillen
                  </Text>
                </View>
                <View
                  onPress={() =>
                    navigation.navigate("SingleStore", {
                      action: state.action,
                      store: state.store,
                    })
                  }
                  style={{ marginBottom: "5%" }}
                >
                  <View
                    style={[
                      classes.card,
                      { paddingBottom: 10, paddingTop: 15 },
                    ]}
                  >
                    {!state.editStore ? (
                      <View style={{ flex: 1 }}>
                        <Animatable.View
                          easing="ease-in"
                          duration={300}
                          animation="fadeIn"
                        >
                          <View style={[classes.row, { marginBottom: 10 }]}>
                            <View style={{ flex: 1 }}>
                              <Text>Status:</Text>
                            </View>
                            <View style={[classes.row, { marginBottom: 10 }]}>
                              <Text
                                style={{
                                  color:
                                    state.store.status == 2
                                      ? theme.color.succes
                                      : state.store.status == 1
                                      ? theme.color.yellow
                                      : theme.color.error,
                                }}
                              >
                                {state.store.status == 2
                                  ? "Bestätigt"
                                  : state.store.status == 1
                                  ? "Gespeichert"
                                  : "Offen"}
                              </Text>
                              <View
                                style={[
                                  classes.circle,
                                  {
                                    marginLeft: 10,
                                    alignSelf: "center",
                                    backgroundColor:
                                      state.store.status == 2
                                        ? theme.color.succes
                                        : state.store.status == 1
                                        ? theme.color.yellow
                                        : theme.color.error,
                                  },
                                ]}
                              ></View>
                            </View>
                          </View>
                          {attribute.map((attr) => (
                            <View
                              key={attr.name}
                              style={[classes.row, { marginBottom: 10 }]}
                            >
                              <Text
                                numberOfLines={2}
                                ellipsizeMode="tail"
                                style={{ marginBottom: 10, flex: 1 }}
                              >
                                {attr.name}
                              </Text>
                              <Text
                                selectable={true}
                                numberOfLines={2}
                                ellipsizeMode="tail"
                                style={{ marginBottom: 10 }}
                              >
                                {state.store[attr.value]}
                              </Text>
                            </View>
                          ))}
                          {role == 1 && (
                            <View
                              style={[classes.row, { alignSelf: "flex-end" }]}
                            >
                              {state.store.errorMessage ? (
                                <View
                                  style={{
                                    alignSelf: "center",
                                    marginRight: 20,
                                  }}
                                >
                                  <Text style={classes.error}>
                                    {state.store.errorMessage}
                                  </Text>
                                </View>
                              ) : (
                                state.store.savedErrorMessage && (
                                  <View
                                    style={{
                                      alignSelf: "center",
                                      marginRight: 20,
                                    }}
                                  >
                                    <Text style={classes.error}>
                                      {state.store.savedErrorMessage}
                                    </Text>
                                  </View>
                                )
                              )}
                              <Icon
                                onPress={() =>
                                  setState((s) => ({ ...s, editStore: true }))
                                }
                                tag="MaterialIcons"
                                name="edit"
                                size={24}
                                color={theme.color.gray}
                                style={{ marginRight: 10, paddng: 5 }}
                              />
                              <Icon
                                onPress={
                                  () => {
                                    let conf = confirm(
                                      "Wollen Sie wirklich diese Markt löchen?"
                                    );
                                    if (conf) {
                                      storesApi.delete(state, setState, () =>
                                        navigation.goBack()
                                      );
                                    }
                                  }
                                  // Alert.alert(
                                  //   "Markt Löschen",
                                  //   "Wollen Sie wirklich diese Markt löchen?",
                                  //   [
                                  //     {
                                  //       text: "Abbrechen",
                                  //       onPress: () =>
                                  //         console.log("Cancel Pressed"),
                                  //       style: "cancel",
                                  //     },
                                  //     {
                                  //       text: "Ja",
                                  //       onPress: () =>
                                  //         storesApi.delete(
                                  //           state,
                                  //           setState,
                                  //           () => navigation.goBack()
                                  //         ),
                                  //     },
                                  //   ]
                                  // )
                                }
                                tag="MaterialIcons"
                                name="delete"
                                size={24}
                                color={theme.color.gray}
                                style={{ padding: 5 }}
                              />
                            </View>
                          )}
                        </Animatable.View>
                      </View>
                    ) : (
                      <View>
                        <View>
                          <View style={{ flex: 1 }}>
                            <Text>Status:</Text>
                            <View style={{ position: "relative" }}>
                              <View style={classes.textInput}>
                                <Picker
                                  selectedValue={state.store.status || 0}
                                  onValueChange={(itemValue, itemIndex) =>
                                    changeEventListener(itemValue, "status")
                                  }
                                >
                                  <Picker.Item
                                    label={"Bestätigt"}
                                    style={{ color: theme.color.succes }}
                                    key="Bestätigt"
                                    value={2}
                                  />
                                  <Picker.Item
                                    label={"Gespeichert"}
                                    key={"Gespeichert"}
                                    value={1}
                                    style={{ color: theme.color.yellow }}
                                  />
                                  <Picker.Item
                                    label={"offen"}
                                    key={`offen`}
                                    value={0}
                                    style={{ color: theme.color.error }}
                                  />
                                </Picker>
                              </View>
                              <View
                                style={{
                                  marginTop: 10,
                                  marginBottom: 10,
                                  height: 35,
                                  position: "absolute",
                                  right: 50,
                                  top: 0,
                                  justifyContent: "center",
                                }}
                              >
                                <View
                                  style={[
                                    classes.circle,
                                    {
                                      alignSelf: "flex-end",
                                      backgroundColor:
                                        state.store.status == 2
                                          ? theme.color.succes
                                          : state.store.status == 1
                                          ? theme.color.yellow
                                          : theme.color.error,
                                    },
                                  ]}
                                ></View>
                              </View>
                            </View>
                          </View>
                          {attribute.map((attr) => (
                            <View key={attr.name + "-2"}>
                              <Text>{attr.name}</Text>
                              <TextInput
                                placeholder={attr.name}
                                value={state.store[attr.value]}
                                style={classes.textInput}
                                textContentType="name"
                                name={attr.name}
                                onChangeText={(value) =>
                                  changeEventListener(value, attr.value)
                                }
                              />
                            </View>
                          ))}
                        </View>
                        <View style={[classes.row, { alignSelf: "flex-end" }]}>
                          {state.store.editError?.length > 0 && (
                            <View
                              style={{ alignSelf: "center", marginRight: 20 }}
                            >
                              <Text style={classes.error}>
                                {state.editError}
                              </Text>
                            </View>
                          )}
                          <Icon
                            onPress={() => {
                              setState((s) => ({ ...s, editStore: false }));
                            }}
                            tag="FontAwesome"
                            name="check"
                            size={24}
                            color={theme.color.gray}
                            style={{ marginRight: 10, padding: 5 }}
                          />
                          {/* <Icon
                            onPress={() => {
                              storesApi.getById(state, setState, () =>
                                setState((s) => ({ ...s, editStore: false }))
                              );
                            }}
                            tag="FontAwesome"
                            name="times"
                            size={24}
                            color={theme.color.gray}
                            style={{ marginRight: 10, padding: 5 }}
                          /> */}
                          <Icon
                            onPress={
                              () => {
                                let conf = confirm(
                                  "Wollen Sie wirklich diese Markt löchen?"
                                );
                                if (conf) {
                                  storesApi.delete(state, setState, () =>
                                    navigation.goBack()
                                  );
                                }
                              }
                              // Alert.alert(
                              //   "Aktion Löschen",
                              //   "Wollen Sie wirklich diese Markt löchen?",
                              //   [
                              //     {
                              //       text: "Abbrechen",
                              //       onPress: () =>
                              //         console.log("Cancel Pressed"),
                              //       style: "cancel",
                              //     },
                              //     {
                              //       text: "Ja",
                              //       onPress: () =>
                              //         storesApi.delete(state, setState, () =>
                              //           navigation.goBack()
                              //         ),
                              //     },
                              //   ]
                              // )
                            }
                            tag="MaterialIcons"
                            name="delete"
                            size={24}
                            color={theme.color.gray}
                            style={{ padding: 5 }}
                          />
                        </View>
                      </View>
                    )}
                  </View>
                </View>
                {/* <View style={[classes.row, { marginBottom: 10 }]}>
        <TouchableOpacity
          onPress={() => {}}
          style={[classes.buttonS, { marginRight: 10 }]}
        >
          <Text style={{ color: "#fff" }}>BEARBEITEN</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => {}} style={classes.button}>
          <Text style={{ color: "#fff" }}>BESTÄTIGEN</Text>
        </TouchableOpacity>
      </View> */}
              </View>
              <View style={{ marginTop: 30 }}>
                <Text style={classes.title3}>Personal</Text>
                <View style={[classes.card, { marginTop: 10 }]}>
                  {state.store?.userId && state.store?.user?.id ? (
                    !state.editUser ? (
                      <View>
                        <View style={[classes.row, { marginBottom: 10 }]}>
                          <Text
                            numberOfLines={2}
                            ellipsizeMode="tail"
                            style={{ marginBottom: 10, flex: 1 }}
                          >
                            Name
                          </Text>
                          <Text
                            selectable={true}
                            numberOfLines={2}
                            ellipsizeMode="tail"
                            style={{ marginBottom: 10 }}
                          >
                            {state.store.user.firstname}{" "}
                            {state.store.user.lastname}
                          </Text>
                        </View>
                        {state.store.user.email?.length > 0 && (
                          <TouchableOpacity
                            onPress={() =>
                              Linking.openURL(
                                `mailto:${state.store.user.email}?subject=WMF REPORTING PORTAL`
                              )
                            }
                          >
                            <View style={[classes.row, { marginBottom: 10 }]}>
                              <View
                                style={{
                                  marginBottom: 10,
                                  flex: 1,
                                  alignItems: "flex-start",
                                  paddingLeft: 0,
                                }}
                              >
                                <View>
                                  <Icon
                                    onPress={() =>
                                      Linking.openURL(
                                        `mailto:${state.store.user.email}?subject=WMF REPORTING PORTAL`
                                      )
                                    }
                                    tag="Ionicons"
                                    name="mail"
                                    color={theme.color.gray}
                                    style={{ alignSelf: "flex-start" }}
                                  />
                                </View>
                              </View>
                              <Text
                                selectable={true}
                                numberOfLines={2}
                                ellipsizeMode="tail"
                                style={{ marginBottom: 10 }}
                              >
                                {state.store.user.email}
                              </Text>
                            </View>
                          </TouchableOpacity>
                        )}
                        {state.store.user.phonenumber?.length > 0 && (
                          <TouchableOpacity
                            onPress={() =>
                              Linking.openURL(
                                `tel:${state.store.user.phonenumber}`
                              )
                            }
                          >
                            <View style={[classes.row, { marginBottom: 10 }]}>
                              <View
                                style={{
                                  marginBottom: 10,
                                  flex: 1,
                                  alignItems: "flex-start",
                                  paddingLeft: 0,
                                }}
                              >
                                <View>
                                  <Icon
                                    onPress={() =>
                                      Linking.openURL(
                                        `tel:${state.store.user.phonenumber}`
                                      )
                                    }
                                    tag="FontAwesome5"
                                    name="phone-alt"
                                    color={theme.color.gray}
                                    style={{ alignSelf: "flex-start" }}
                                  />
                                </View>
                              </View>
                              <Text
                                selectable={true}
                                numberOfLines={2}
                                ellipsizeMode="tail"
                                style={{ marginBottom: 10 }}
                              >
                                {state.store.user.phonenumber}
                              </Text>
                            </View>
                          </TouchableOpacity>
                        )}
                        {role == 1 && (
                          <View
                            style={[classes.row, { alignSelf: "flex-end" }]}
                          >
                            <Icon
                              onPress={() => {
                                usersApi.get(state, setState);
                                setState((s) => ({ ...s, editUser: true }));
                              }}
                              tag="MaterialIcons"
                              name="edit"
                              size={24}
                              color={theme.color.gray}
                              style={{ marginRight: 10, paddng: 5 }}
                            />
                            <Icon
                              onPress={
                                () => {
                                  let conf = confirm(
                                    "Wollen Sie wirklich dieses Personal entfernen?"
                                  );
                                  if (conf) {
                                    setState((s) => ({
                                      ...s,
                                      store: {
                                        ...s.store,
                                        userId: null,
                                        user: null,
                                      },
                                    }));
                                  }
                                }
                                // Alert.alert(
                                //   "Personal Entfernen",
                                //   "Wollen Sie wirklich dieses Personal entfernen?",
                                //   [
                                //     {
                                //       text: "Abbrechen",
                                //       onPress: () =>
                                //         console.log("Cancel Pressed"),
                                //       style: "cancel",
                                //     },
                                //     {
                                //       text: "Ja",
                                //       onPress: () =>
                                //         setState((s) => ({
                                //           ...s,
                                //           store: {
                                //             ...s.store,
                                //             userId: null,
                                //             user: null,
                                //           },
                                //         })),
                                //     },
                                //   ]
                                // )
                              }
                              tag="MaterialIcons"
                              name="delete"
                              size={24}
                              color={theme.color.gray}
                              style={{ padding: 5 }}
                            />
                          </View>
                        )}
                      </View>
                    ) : state.users?.length > 0 ? (
                      <View>
                        <Text>Personal auswählen:</Text>
                        <View style={classes.textInput}>
                          <Picker
                            selectedValue={state.store.userId}
                            onValueChange={(value, index) =>
                              setState((s) => ({
                                ...s,
                                store: {
                                  ...s.store,
                                  userId: value,
                                  user: state.users.filter(
                                    (u) => u.id == value
                                  )[0],
                                },
                              }))
                            }
                          >
                            <Picker.Item label="Bitte Personal auswählen..." />
                            {state.users
                              .filter((u) => u.roles[0].value == 3)
                              .sort((a, b) =>
                                `${a.firstname} ${a.lastname}`.localeCompare(
                                  `${b.firstname} ${b.lastname}`
                                )
                              )
                              .map((item, index) => (
                                <Picker.Item
                                  label={`${item.firstname} ${item.lastname}`}
                                  key={`${item.id}`}
                                  value={item.id}
                                />
                              ))}
                          </Picker>
                        </View>
                        <View style={[classes.row, { alignSelf: "flex-end" }]}>
                          {state.store.editError?.length > 0 && (
                            <View
                              style={{ alignSelf: "center", marginRight: 20 }}
                            >
                              <Text style={classes.error}>
                                {state.editError}
                              </Text>
                            </View>
                          )}
                          <Icon
                            onPress={() => {
                              setState((s) => ({ ...s, editUser: false }));
                            }}
                            tag="FontAwesome"
                            name="check"
                            size={24}
                            color={theme.color.gray}
                            style={{ marginRight: 10, padding: 5 }}
                          />
                          <Icon
                            onPress={
                              () => {
                                let conf = confirm(
                                  "Wollen Sie wirklich dieses Personal entfernen?"
                                );
                                if (conf) {
                                  setState((s) => ({
                                    ...s,
                                    store: {
                                      ...s.store,
                                      userId: null,
                                      user: null,
                                    },
                                  }));
                                }
                              }
                              // Alert.alert(
                              //   "Personal entfernen",
                              //   "Wollen Sie wirklich dieses Personal entfernen?",
                              //   [
                              //     {
                              //       text: "Abbrechen",
                              //       onPress: () =>
                              //         console.log("Cancel Pressed"),
                              //       style: "cancel",
                              //     },
                              //     {
                              //       text: "Ja",
                              //       onPress: () =>
                              //         setState((s) => ({
                              //           ...s,
                              //           store: {
                              //             ...s.store,
                              //             userId: null,
                              //             user: null,
                              //           },
                              //         })),
                              //     },
                              //   ]
                              // )
                            }
                            tag="MaterialIcons"
                            name="delete"
                            size={24}
                            color={theme.color.gray}
                            style={{ padding: 5 }}
                          />
                        </View>
                      </View>
                    ) : (
                      <View>
                        <SkeletonContent
                          containerStyle={{ flex: 1 }}
                          isLoading={true}
                          layout={[
                            {
                              key: "skeletonItem1",
                              width: "100%",
                              height: 93.8,
                              borderRadius: 0,
                            },
                          ]}
                        ></SkeletonContent>
                      </View>
                    )
                  ) : (
                    <View>
                      {!state.editUser ? (
                        <View>
                          <Text>Kein Personal vorhanden.</Text>
                          <Text style={{ color: "gray" }}>
                            Bitte klicken Sie auf den Stift, um ein Personal
                            hinzuzufügen.
                          </Text>
                          {role == 1 && (
                            <View
                              style={[classes.row, { alignSelf: "flex-end" }]}
                            >
                              <Icon
                                onPress={() => {
                                  usersApi.get(state, setState);
                                  setState((s) => ({ ...s, editUser: true }));
                                }}
                                tag="MaterialIcons"
                                name="edit"
                                size={24}
                                color={theme.color.gray}
                                style={{ marginRight: 10, paddng: 5 }}
                              />
                              <Icon
                                onPress={
                                  () => {
                                    let conf = confirm(
                                      "Wollen Sie wirklich dieses Personal entfernen?"
                                    );
                                    if (conf) {
                                      setState((s) => ({
                                        ...s,
                                        store: {
                                          ...s.store,
                                          userId: null,
                                          user: null,
                                        },
                                      }));
                                    }
                                  }
                                  // Alert.alert(
                                  //   "Personal Entfernen",
                                  //   "Wollen Sie wirklich dieses Personal entfernen?",
                                  //   [
                                  //     {
                                  //       text: "Abbrechen",
                                  //       onPress: () =>
                                  //         console.log("Cancel Pressed"),
                                  //       style: "cancel",
                                  //     },
                                  //     {
                                  //       text: "Ja",
                                  //       onPress: () =>
                                  //         setState((s) => ({
                                  //           ...s,
                                  //           store: {
                                  //             ...s.store,
                                  //             userId: null,
                                  //             user: null,
                                  //           },
                                  //         })),
                                  //     },
                                  //   ]
                                  // )
                                }
                                tag="MaterialIcons"
                                name="delete"
                                size={24}
                                color={theme.color.gray}
                                style={{ padding: 5 }}
                              />
                            </View>
                          )}
                        </View>
                      ) : state.users?.length > 0 ? (
                        <View>
                          <Text>Personal auswählen:</Text>
                          <View style={classes.textInput}>
                            <Picker
                              selectedValue={state.store.userId}
                              onValueChange={(value, index) =>
                                setState((s) => ({
                                  ...s,
                                  store: {
                                    ...s.store,
                                    userId: value,
                                    user: state.users.filter(
                                      (u) => u.id == value
                                    )[0],
                                  },
                                }))
                              }
                            >
                              <Picker.Item label="Bitte Personal auswählen..." />
                              {state.users
                                .filter((u) => u.roles[0].value == 3)
                                .map((item, index) => (
                                  <Picker.Item
                                    label={`${item.firstname} ${item.lastname}`}
                                    key={`${item.id}`}
                                    value={item.id}
                                  />
                                ))}
                            </Picker>
                          </View>
                          <View
                            style={[classes.row, { alignSelf: "flex-end" }]}
                          >
                            {state.store.editError?.length > 0 && (
                              <View
                                style={{ alignSelf: "center", marginRight: 20 }}
                              >
                                <Text style={classes.error}>
                                  {state.editError}
                                </Text>
                              </View>
                            )}
                            <Icon
                              onPress={() => {
                                setState((s) => ({ ...s, editUser: false }));
                              }}
                              tag="FontAwesome"
                              name="check"
                              size={24}
                              color={theme.color.gray}
                              style={{ marginRight: 10, padding: 5 }}
                            />
                            {/* <Icon
                              onPress={() => {
                                storesApi.getById(state, setState, () =>
                                  setState((s) => ({ ...s, editUser: false }))
                                );
                              }}
                              tag="FontAwesome"
                              name="times"
                              size={24}
                              color={theme.color.gray}
                              style={{ marginRight: 10, padding: 5 }}
                            /> */}
                            <Icon
                              onPress={
                                () => {
                                  let conf = confirm(
                                    "Wollen Sie wirklich dieses Personal entfernen?"
                                  );
                                  if (conf) {
                                    setState((s) => ({
                                      ...s,
                                      store: {
                                        ...s.store,
                                        userId: null,
                                        user: null,
                                      },
                                    }));
                                  }
                                }
                                // Alert.alert(
                                //   "Personal entfernen",
                                //   "Wollen Sie wirklich dieses Personal entfernen?",
                                //   [
                                //     {
                                //       text: "Abbrechen",
                                //       onPress: () =>
                                //         console.log("Cancel Pressed"),
                                //       style: "cancel",
                                //     },
                                //     {
                                //       text: "Ja",
                                //       onPress: () =>
                                //         setState((s) => ({
                                //           ...s,
                                //           store: {
                                //             ...s.store,
                                //             userId: null,
                                //             user: null,
                                //           },
                                //         })),
                                //     },
                                //   ]
                                // )
                              }
                              tag="MaterialIcons"
                              name="delete"
                              size={24}
                              color={theme.color.gray}
                              style={{ padding: 5 }}
                            />
                          </View>
                        </View>
                      ) : (
                        <View>
                          <SkeletonContent
                            containerStyle={{ flex: 1 }}
                            isLoading={true}
                            layout={[
                              {
                                key: "skeletonItem1",
                                width: "100%",
                                height: 93.8,
                                borderRadius: 0,
                              },
                            ]}
                          ></SkeletonContent>
                        </View>
                      )}
                    </View>
                  )}
                </View>
              </View>
              {state.action.inputs.length > 0 && (
                <View style={{ paddingBottom: 10, paddingTop: 30 }}>
                  <View>
                    <Text style={[classes.title3, { marginBottom: 10 }]}>
                      Abfrage
                    </Text>
                  </View>
                  <View>
                    {state.store.submitedInputs.length > 0 &&
                      state.action.inputs.map((input, inputIndex) =>
                        input.type == "Text" ? (
                          <View
                            key={input.id}
                            style={[classes.card, { marginBottom: "5%" }]}
                          >
                            <Text>
                              {input.label}{" "}
                              {input.required ? "*" : "(Optional)"}
                            </Text>
                            <TextInput
                              style={classes.textInput}
                              value={
                                state.store.submitedInputs?.filter(
                                  (i) => i.inputId == input.id
                                )[0]?.value || ""
                              }
                              placeholder={input.placeholder}
                              onChangeText={(value) =>
                                changeSubmitedInput(value, input.id)
                              }
                            />
                          </View>
                        ) : input.type == "Checkbox" ? (
                          <View
                            key={input.id}
                            style={[classes.card, { marginBottom: "5%" }]}
                          >
                            <Text>{input.label}</Text>
                            <View
                              style={[
                                classes.checkboxContainer,
                                { marginTop: 5 },
                              ]}
                            >
                              <CheckBox
                                value={
                                  state.store.submitedInputs?.filter(
                                    (i) => i.inputId == input.id
                                  )[0]?.value
                                }
                                style={classes.checkbox}
                                onValueChange={(value) =>
                                  changeSubmitedInput(value, input.id)
                                }
                              />
                              <Text style={classes.label}>
                                {input.description}
                              </Text>
                            </View>
                          </View>
                        ) : input.type == "Dropdown" ? (
                          <View
                            key={input.id}
                            style={[classes.card, { marginBottom: "5%" }]}
                          >
                            <Text>
                              {input.label}{" "}
                              {input.required ? "*" : "(Optional)"}
                            </Text>
                            <View style={classes.textInput}>
                              <Picker
                                selectedValue={
                                  state.store.submitedInputs?.filter(
                                    (i) => i.inputId == input.id
                                  )[0]?.value || ""
                                }
                                onValueChange={(value, index) =>
                                  changeSubmitedInput(value, input.id)
                                }
                              >
                                <Picker.Item label="Bitte wählen..." />
                                {input.items.map((item, index) => (
                                  <Picker.Item
                                    label={item.value}
                                    key={`${index.toString()}${index}`}
                                    value={item.value}
                                  />
                                ))}
                              </Picker>
                            </View>
                          </View>
                        ) : input.type == "Uploader" ? (
                          <View
                            key={input.id}
                            style={[classes.card, { marginBottom: "5%" }]}
                          >
                            <Text>
                              {input.label}{" "}
                              {input.required ? "*" : "(Optional)"}
                            </Text>
                            {input.description?.length > 0 && (
                              <Text style={{ color: "gray" }}>
                                {input.description == "image"
                                  ? "Nur Bilder"
                                  : input.description == "pdf"
                                  ? "Nur PDFs"
                                  : input.description == "video" &&
                                    "Nur Videos"}
                              </Text>
                            )}
                            <View
                              style={[
                                classes.row,
                                { flexWrap: "wrap", marginTop: 10 },
                              ]}
                            >
                              {state.store.submitedInputs
                                ?.filter((i) => i.inputId == input.id)[0]
                                ?.files?.map((file, fileIndex) => (
                                  <View
                                    key={fileIndex}
                                    style={{
                                      borderWidth: 1,
                                      borderColor: "#eee",
                                      borderStyle: "solid",
                                      backgroundColor: "#eee",
                                      width: "50%",
                                      maxHeight:
                                        file.description == "image"
                                          ? "auto"
                                          : "auto",
                                    }}
                                  >
                                    {file.type == "image" ? (
                                      <TouchableOpacity
                                        onPress={() =>
                                          setState((s) => ({
                                            ...s,
                                            selectedFiles:
                                              state.store.submitedInputs?.filter(
                                                (i) => i.inputId == input.id
                                              )[0]?.files,
                                            selectedIndex: fileIndex,
                                          }))
                                        }
                                        style={{
                                          position: "relative",
                                          justifyContent: "center",
                                          backgroundColor: "#adf",
                                        }}
                                      >
                                        <View
                                          style={{
                                            position: "relative",
                                            justifyContent: "center",
                                            width: "100%",
                                            height: "100%",
                                          }}
                                        >
                                          <img
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              //resizeMode="cover"
                                              //overflow: "visible",
                                            }}
                                            src={
                                              file.path
                                                ? getImage(file.path)
                                                : file.uri &&
                                                  URL.createObjectURL(file.uri)
                                            }
                                          />
                                        </View>
                                        {role != 2 && (
                                          <View
                                            style={{
                                              position: "absolute",
                                              top: 5,
                                              right: 7,
                                            }}
                                          >
                                            <Icon
                                              onPress={() =>
                                                setState((s) => ({
                                                  ...s,
                                                  store: {
                                                    ...s.store,
                                                    submitedInputs:
                                                      s.store.submitedInputs.map(
                                                        (si) =>
                                                          si.inputId == input.id
                                                            ? {
                                                                ...si,
                                                                files:
                                                                  si.files.filter(
                                                                    (f, idx) =>
                                                                      idx !=
                                                                      fileIndex
                                                                  ),
                                                              }
                                                            : si
                                                      ),
                                                  },
                                                }))
                                              }
                                              tag="FontAwesome"
                                              name="times"
                                              size={25}
                                              color={
                                                input.description?.length > 0
                                                  ? input.description == "image"
                                                    ? "#7ad673"
                                                    : input.description == "pdf"
                                                    ? "#ea6060"
                                                    : input.description ==
                                                        "video" && "#efc053"
                                                  : "#888"
                                              }
                                            />
                                          </View>
                                        )}
                                      </TouchableOpacity>
                                    ) : file.type == "video" ? (
                                      <View>
                                        <Video
                                          ref={video}
                                          style={{ width: "100%" }}
                                          source={{
                                            uri: file.uri,
                                          }}
                                          useNativeControls
                                          resizeMode="contain"
                                          isLooping
                                        />
                                      </View>
                                    ) : (
                                      <TouchableOpacity
                                        style={{
                                          backgroundColor: "#fff",
                                          height: 120,
                                          justifyContent: "center",
                                          padding: 5,
                                        }}
                                        onPress={() => linking(file.url)}
                                      >
                                        <Icon
                                          tag="MaterialCommunityIcons"
                                          size={50}
                                          name="file-pdf"
                                          color="#ea6060"
                                          onPress={() => linking(file.url)}
                                        />
                                        <Text
                                          style={{
                                            fontSize: 13,
                                            color: "gray",
                                            alignSelf: "center",
                                            marginBottom: 5,
                                          }}
                                          numberOfLines={2}
                                          ellipsizeMode="tail"
                                        >
                                          {file.uri?.name}
                                        </Text>
                                      </TouchableOpacity>
                                    )}
                                  </View>
                                ))}
                              {role != 2 && (
                                <TouchableOpacity
                                  style={{
                                    borderWidth: 1,
                                    borderColor: "#eee",
                                    borderStyle: "solid",
                                    backgroundColor:
                                      input.description?.length > 0
                                        ? input.description == "image"
                                          ? "#7ad67320"
                                          : input.description == "pdf"
                                          ? "#ea606020"
                                          : input.description == "video" &&
                                            "#efc05320"
                                        : "#888",
                                    padding: 10,
                                    width:
                                      input.description == "image" ||
                                      input.description == "pdf"
                                        ? "50%"
                                        : "100%",
                                    height:
                                      input.description == "image"
                                        ? "auto"
                                        : "auto",
                                    justifyContent: "center",
                                  }}
                                  onPress={() =>
                                    role != 2 &&
                                    (input.description == "pdf"
                                      ? savePdf(input.id)
                                      : pickImage(input.description, input.id))
                                  }
                                  //onPress={saveFiles}
                                >
                                  <Icon
                                    tag="MaterialCommunityIcons"
                                    size={50}
                                    name={
                                      input.description?.length > 0
                                        ? input.description == "image"
                                          ? "file-image"
                                          : input.description == "pdf"
                                          ? "file-pdf"
                                          : input.description == "video" &&
                                            "file-video"
                                        : "file"
                                    }
                                    color={
                                      input.description?.length > 0
                                        ? input.description == "image"
                                          ? "#7ad673"
                                          : input.description == "pdf"
                                          ? "#ea6060"
                                          : input.description == "video" &&
                                            "#efc053"
                                        : "white"
                                    }
                                    onPress={() =>
                                      input.description == "pdf"
                                        ? savePdf(input.id)
                                        : pickImage(input.description, input.id)
                                    }
                                  />
                                  <Text
                                    style={{
                                      fontSize: 13,
                                      fontWeight: "700",
                                      color:
                                        input.description?.length > 0
                                          ? input.description == "image"
                                            ? "#7ad673"
                                            : input.description == "pdf"
                                            ? "#ea6060"
                                            : input.description == "video" &&
                                              "#efc053"
                                          : "#888",
                                      alignSelf: "center",
                                    }}
                                  >
                                    {input.description?.length > 0
                                      ? input.description == "image"
                                        ? "Bilder"
                                        : input.description == "pdf"
                                        ? "PDFs"
                                        : input.description == "video" &&
                                          "Videos"
                                      : "Dateien"}{" "}
                                    hinzufügen
                                  </Text>
                                </TouchableOpacity>
                              )}
                            </View>
                          </View>
                        ) : (
                          <View key={input.id}></View>
                        )
                      )}
                  </View>
                </View>
              )}
            </Animatable.View>
          ) : (
            <Animatable.View
              easing="ease-in-out"
              duration={500}
              animation="fadeIn"
            >
              <SkeletonContent
                containerStyle={{ flex: 1 }}
                isLoading={true}
                layout={[
                  {
                    key: "skeletonItem1",
                    width: "100%",
                    height: 77,
                    marginBottom: "5%",
                    borderRadius: 0,
                  },
                  {
                    key: "skeletonItem2",
                    width: "100%",
                    height: 77,
                    marginBottom: "5%",
                    borderRadius: 0,
                  },
                  {
                    key: "skeletonItem3",
                    width: "100%",
                    height: 139,
                    marginBottom: "5%",
                    borderRadius: 0,
                  },
                  {
                    key: "skeletonItem4",
                    width: "100%",
                    height: 139,
                    marginBottom: "5%",
                    borderRadius: 0,
                  },
                  {
                    key: "skeletonItem5",
                    width: "100%",
                    height: 139,
                    marginBottom: "5%",
                    borderRadius: 0,
                  },
                  {
                    key: "skeletonItem6",
                    width: "100%",
                    height: 139,
                    marginBottom: "5%",
                    borderRadius: 0,
                  },
                ]}
              ></SkeletonContent>
            </Animatable.View>
          )}
        </View>
      </ScrollView>
      {state.selectedFiles && state.selectedIndex >= 0 && (
        <Carousel
          parentState={state}
          setParentState={setState}
          files={state.selectedFiles}
          index={state.selectedIndex}
        />
      )}
      <View style={[classes.row]}>
        <View
          style={{
            width: "50%",
            paddingHorizontal: 10,
          }}
        >
          <TouchableOpacity
            disabled={state.loading}
            onPress={() => navigation.goBack()}
            style={[classes.buttonS]}
          >
            <Text style={{ color: "#fff", alignSelf: "center" }}>Zurück</Text>
          </TouchableOpacity>
        </View>
        <View
          style={{
            width: "50%",
            paddingHorizontal: 10,
          }}
        >
          {role != 2 && (
            <TouchableOpacity
              disabled={state.loading}
              onPress={() => {
                storesApi.update(
                  {
                    ...state,
                    store: {
                      ...state.store,
                      status: role == 3 ? 1 : state.store.status,
                    },
                  },
                  setState,
                  () => {}
                );
              }}
              style={[classes.button, classes.row]}
            >
              <Text style={{ color: "#fff", alignSelf: "center" }}>
                SPEICHERN
              </Text>
              {state.loading && (
                <ActivityIndicator
                  style={{ alignSelf: "center", marginLeft: 5 }}
                  size="small"
                  color="#fff"
                />
              )}
            </TouchableOpacity>
          )}
        </View>
      </View>
    </View>
  );
};
