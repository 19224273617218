import React, { useEffect, useState } from 'react';
import { StyleSheet, View, FlatList } from 'react-native';
import {Icon} from './Icon';
import {theme} from '../helpers';

export const RatingStars = ({ rating }) => {
    const [state, setState] = useState([0, 0, 0, 0, 0]);
    useEffect(() => {
        let newState = state;
        for (var i = 1; i < 6; i++) {
            newState[i - 1] = rating - i >= 0 ? 1 : -0.5 <= rating - i && rating - i < 0 ? 2 : 0;
        }
        setState([...newState]);
    }, []);

    return (
        <View>
            <FlatList
                style={styles.container}
                horizontal
                data={state}
                renderItem={({ item }) =>
                    item === 2 ? (
                        <View style={styles.containerHalf}>
                            <Icon tag="FontAwesome" name="star-half" style={styles.activateHalf} />
                            <Icon
                                tag="FontAwesome"
                                name="star-half"
                                style={styles.noActivateHalf}
                            />
                        </View>
                    ) : (
                        <Icon
                            tag="FontAwesome"
                            name="star"
                            style={item === 1 ? styles.activate : styles.noActivate}
                        />
                    )
                }
            />
        </View>
    );
};

const styles = StyleSheet.create({
    containerHalf: {
        flexDirection: 'row',
        margin: 2,
    },
    activate: {
        color: theme.color.primary,
        fontSize: 20,
        margin: 2,
    },
    activateHalf: {
        color: theme.color.primary,
        fontSize: 20,
        margin: 0,
        marginRight: -0.7,
    },
    noActivate: {
        color: '#ddd',
        fontSize: 20,
        margin: 2,
    },
    noActivateHalf: {
        color: '#ddd',
        fontSize: 20,
        marginLeft: -0.6,
        transform: [{ rotateY: '180deg' }],
    },
});