import React, { useEffect, useRef, useState } from "react";

import {
  ActivityIndicator,
  Alert,
  Button,
  FlatList,
  Linking,
  Platform,
  RefreshControl,
  ScrollView,
  Text,
  TouchableHighlight,
  TouchableOpacity,
  View,
} from "react-native";
import { classes, theme, usersApi } from "../helpers";
import { FilterModal, FloatingButton, Icon } from "../Utils";
import SkeletonContent from "react-native-skeleton-content";
import * as Animatable from "react-native-animatable";
import { TextInput } from "react-native-gesture-handler";
import { Picker } from "@react-native-picker/picker";

const wait = (timeout) => {
  return new Promise((resolve) => setTimeout(resolve, timeout));
};

export const AllPersonals = ({ route, user, role, navigation }) => {
  const [state, setState] = useState({
    user,
    role,
  });

  const onRefresh = React.useCallback(() => {
    usersApi.get(state, setState);

    //wait(2000).then(() => setRefreshing(false));
  }, []);

  useEffect(() => {
    //getusers(state, setState);
  }, []);

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      usersApi.get(state, setState);
    });

    return unsubscribe;
  }, [navigation]);
  const scrollRefPLAction = useRef();

  const onFabPress = () => {
    scrollRefPLAction.current?.scrollTo({
      y: 0,
      animated: true,
    });
  };
  function validate(id) {
    let result = true;
    setState((s) => ({
      ...s,
      users: s.users.map((u) => {
        if ((!u.id && !id) || u.id == id) {
          result =
            u.password && u.firstname && u.lastname && u.roles[0]?.value > 0;
          return {
            ...u,
            passwordError: !u.password,
            firstnameError: !u.firstname,
            lastnameError: !u.lastname,
            roleError: !(u.roles[0]?.value > 0),
          };
        }
        return u;
      }),
    }));
    return result;
  }
  function validateOnUpdate(id) {
    let result = true;
    setState((s) => ({
      ...s,
      users: s.users.map((u) => {
        if ((!u.id && !id) || u.id == id) {
          result = u.firstname && u.lastname && u.roles[0]?.value;
          return {
            ...u,
            firstnameError: !u.firstname,
            lastnameError: !u.lastname,
            roleError: !u.roles[0]?.value,
          };
        }
        return u;
      }),
    }));
    return result;
  }
  return (
    <View style={{ flex: 1 }}>
      <ScrollView
        ref={scrollRefPLAction}
        refreshControl={
          <RefreshControl refreshing={state.loading} onRefresh={onRefresh} />
        }
      >
        <View style={[classes.screen, { maxWidth: 700 }]}>
          {state.users?.length >= 0 ? (
            <Animatable.View id="aview1" easing="ease-in" animation="fadeIn">
              {state.users.map((item, index) => (
                <View key={`${item.id}benutzer`}>
                  {item.edit == true ? (
                    <View
                      style={{
                        marginBottom: "5%",
                        backgroundColor: "#fff",
                        padding: 15,
                      }}
                    >
                      <View>
                        <Text>Rolle *</Text>
                        {item.roleError == true && (
                          <Text style={classes.error}>Pfichtfeld</Text>
                        )}
                        <View style={classes.textInput}>
                          <Picker
                            selectedValue={item.roles[0]?.value}
                            onValueChange={(value, index) =>
                              setState((s) => ({
                                ...s,
                                users: s.users.map((u) =>
                                  u.id == item.id
                                    ? {
                                        ...u,
                                        roles: [{ value: value }],
                                      }
                                    : u
                                ),
                              }))
                            }
                          >
                            <Picker.Item
                              label="Bitte Role auswählen..."
                              value={0}
                            />
                            <Picker.Item
                              label="Projektleiter"
                              key="1"
                              value={1}
                            />
                            <Picker.Item label="Kunde" key="2" value={2} />
                            <Picker.Item label="ADM" key="3" value={3} />
                          </Picker>
                        </View>
                      </View>
                      <View>
                        <Text>Vorname *</Text>
                        {item.firstnameError == true && (
                          <Text style={classes.error}>Pfichtfeld</Text>
                        )}
                        <TextInput
                          style={classes.textInput}
                          placeholder="Vorname"
                          value={item.firstname}
                          onChangeText={(value) =>
                            setState((s) => ({
                              ...s,
                              users: s.users.map((u) =>
                                u.id == item.id ? { ...u, firstname: value } : u
                              ),
                            }))
                          }
                        />
                      </View>
                      <View>
                        <Text>Nachname *</Text>
                        {item.lastnameError == true && (
                          <Text style={classes.error}>Pfichtfeld</Text>
                        )}
                        <TextInput
                          style={classes.textInput}
                          value={item.lastname}
                          placeholder="Nachname"
                          onChangeText={(value) =>
                            setState((s) => ({
                              ...s,
                              users: s.users.map((u) =>
                                u.id == item.id ? { ...u, lastname: value } : u
                              ),
                            }))
                          }
                        />
                      </View>
                      <View>
                        <Text>Passwort {!item.id && "*"}</Text>
                        {item.passwordError == true && (
                          <Text style={classes.error}>Pfichtfeld</Text>
                        )}
                        <TextInput
                          style={classes.textInput}
                          value={item.password}
                          placeholder={
                            item.id ? "Passwort nicht geändert" : "passwort"
                          }
                          onChangeText={(value) =>
                            setState((s) => ({
                              ...s,
                              users: s.users.map((u) =>
                                u.id == item.id ? { ...u, password: value } : u
                              ),
                            }))
                          }
                        />
                      </View>
                      <View>
                        <Text>Email</Text>
                        {item.emailError == true && (
                          <Text style={classes.error}>Pfichtfeld</Text>
                        )}
                        <TextInput
                          style={classes.textInput}
                          value={item.email}
                          placeholder="Email"
                          onChangeText={(value) =>
                            setState((s) => ({
                              ...s,
                              users: s.users.map((u) =>
                                u.id == item.id ? { ...u, email: value } : u
                              ),
                            }))
                          }
                        />
                      </View>
                      <View>
                        <Text>Phonenummer</Text>
                        {item.phonenumberError == true && (
                          <Text style={classes.error}>Pfichtfeld</Text>
                        )}
                        <TextInput
                          style={classes.textInput}
                          value={item.phonenumber}
                          placeholder="Phonenumber"
                          onChangeText={(value) =>
                            setState((s) => ({
                              ...s,
                              users: s.users.map((u) =>
                                u.id == item.id
                                  ? { ...u, phonenumber: value }
                                  : u
                              ),
                            }))
                          }
                        />
                      </View>

                      <View style={[classes.row, { alignSelf: "flex-end" }]}>
                        {item.editError?.length > 0 && (
                          <View
                            style={{ alignSelf: "center", marginRight: 20 }}
                          >
                            <Text style={classes.error}>{state.editError}</Text>
                          </View>
                        )}
                        <Icon
                          onPress={() => {
                            item.id
                              ? validateOnUpdate(item.id) &&
                                usersApi.update(item, state, setState)
                              : validate(item.id) &&
                                usersApi.create(state, setState);
                          }}
                          tag="FontAwesome"
                          name="check"
                          size={24}
                          color={theme.color.gray}
                          style={{ marginRight: 10, padding: 5 }}
                        />
                        <Icon
                          onPress={() => usersApi.get(state, setState)}
                          tag="FontAwesome"
                          name="times"
                          size={24}
                          color={theme.color.gray}
                          style={{ padding: 5 }}
                        />
                      </View>
                    </View>
                  ) : (
                    <View
                      style={{
                        marginBottom: "5%",
                        backgroundColor: "#fff",
                        padding: 15,
                      }}
                    >
                      <View>
                        <View style={[classes.row, { marginBottom: 10 }]}>
                          <View
                            style={{ justifyContent: "flex-start", flex: 1 }}
                          >
                            <Text
                              selectable={true}
                              numberOfLines={2}
                              ellipsizeMode="tail"
                              style={{
                                fontWeight: "700",
                              }}
                            >
                              {item.firstname} {item.lastname} ({item.id})
                            </Text>
                          </View>
                          <View style={{ justifyContent: "flex-end" }}>
                            <Text>
                              {item.roles[0]?.value == 1
                                ? "Projektleiter"
                                : item.roles[0]?.value == 2
                                ? "Kunde"
                                : "ADM"}
                            </Text>
                          </View>
                        </View>
                        {item.roles[0]?.value == 3 && (
                          <View style={[classes.row, { marginBottom: 10 }]}>
                            <Text
                              style={{
                                marginRight: 2,
                                color: theme.color.succes,
                                fontWeight: "500",
                              }}
                            >
                              {item.stores.filter((s) => s.status == 1).length}
                            </Text>
                            <Text>|</Text>
                            <Text
                              style={{
                                marginRight: 2,
                                marginLeft: 2,
                                color: theme.color.yellow,
                                fontWeight: "500",
                              }}
                            >
                              {item.stores.filter((s) => s.status == 2).length}
                            </Text>
                            <Text>|</Text>
                            <Text
                              style={{
                                marginLeft: 2,
                                color: theme.color.error,
                                fontWeight: "500",
                              }}
                            >
                              {
                                item.stores.filter(
                                  (s) => s.status != 1 && s.status != 2
                                ).length
                              }
                            </Text>
                          </View>
                        )}
                        {item.email?.length > 0 && (
                          <TouchableOpacity
                            onPress={() =>
                              Linking.openURL(
                                `mailto:${item.email}?subject=WMF REPORTING PORTAL`
                              )
                            }
                          >
                            <View style={[classes.row, { marginBottom: 10 }]}>
                              <View
                                style={{
                                  alignItems: "flex-start",
                                  paddingRight: 10,
                                }}
                              >
                                <View>
                                  <Icon
                                    onPress={() =>
                                      Linking.openURL(
                                        `mailto:${item.email}?subject=WMF REPORTING PORTAL`
                                      )
                                    }
                                    tag="Ionicons"
                                    name="mail"
                                    color={theme.color.gray}
                                    style={{ alignSelf: "flex-start" }}
                                  />
                                </View>
                              </View>
                              <Text
                                selectable={true}
                                numberOfLines={2}
                                ellipsizeMode="tail"
                              >
                                {item.email}
                              </Text>
                            </View>
                          </TouchableOpacity>
                        )}
                        {item.phonenumber?.length > 0 && (
                          <TouchableOpacity
                            onPress={() =>
                              Linking.openURL(`tel:${item.phonenumber}`)
                            }
                          >
                            <View style={[classes.row, { marginBottom: 10 }]}>
                              <View
                                style={{
                                  alignItems: "flex-start",
                                  paddingRight: 10,
                                }}
                              >
                                <View>
                                  <Icon
                                    onPress={() =>
                                      Linking.openURL(`tel:${item.phonenumber}`)
                                    }
                                    tag="FontAwesome5"
                                    name="phone-alt"
                                    color={theme.color.gray}
                                    style={{ alignSelf: "flex-start" }}
                                  />
                                </View>
                              </View>
                              <Text
                                selectable={true}
                                numberOfLines={2}
                                ellipsizeMode="tail"
                              >
                                {item.phonenumber}
                              </Text>
                            </View>
                          </TouchableOpacity>
                        )}
                        <View style={[classes.row, { alignSelf: "flex-end" }]}>
                          <Icon
                            onPress={() => {
                              setState((s) => ({
                                ...s,
                                users: s.users.map((a) =>
                                  a.id == item.id
                                    ? { ...a, edit: true, error: "" }
                                    : a
                                ),
                              }));
                            }}
                            tag="MaterialIcons"
                            name="edit"
                            size={24}
                            color={theme.color.gray}
                            style={{ marginRight: 10, paddng: 5 }}
                          />
                          <Icon
                            onPress={() => {
                              let confirmaton = confirm(
                                "Wollen Sie wirklich diesen Benutzer löschen?"
                              );
                              if (confirmaton)
                                usersApi.delete(
                                  { ...state, id: item.id },
                                  setState
                                );
                            }}
                            tag="MaterialIcons"
                            name="delete"
                            size={24}
                            color={theme.color.gray}
                            style={{ padding: 5 }}
                          />
                        </View>
                      </View>
                    </View>
                  )}
                </View>
              ))}
            </Animatable.View>
          ) : (
            <Animatable.View
              id="aview2"
              easing="ease-in-out"
              duration={500}
              animation="fadeIn"
            >
              <View>
                <SkeletonContent
                  containerStyle={{ flex: 1 }}
                  isLoading={true}
                  layout={[
                    {
                      key: "skeletonItem1",
                      width: "100%",
                      height: 132,
                      marginBottom: "5%",
                      borderRadius: 0,
                    },
                    {
                      key: "skeletonItem12",
                      width: "100%",
                      height: 132,
                      marginBottom: "5%",
                      borderRadius: 0,
                    },
                  ]}
                ></SkeletonContent>
              </View>
            </Animatable.View>
          )}

          <TouchableOpacity
            disabled={state.users && !state.users[state.users.length - 1]?.id}
            onPress={() =>
              state.users &&
              state.users[state.users.length - 1]?.id &&
              setState((s) => ({
                ...s,
                newUser: true,
                users: [
                  ...s.users,
                  {
                    firstname: "",
                    lastname: "",
                    phonenumber: "",
                    email: "",
                    password: "",
                    roles: [{ value: 3 }],
                    edit: true,
                  },
                ],
              }))
            }
            style={[
              {
                marginBottom: "5%",
                backgroundColor:
                  state.users && state.users[state.users.length - 1]?.id
                    ? "#888"
                    : "#ccc",
                padding: 10,
              },
            ]}
          >
            <View style={classes.row}>
              <View style={{ marginRight: 10 }}>
                <Icon
                  tag="Entypo"
                  name="plus"
                  size={30}
                  color="#fff"
                  onPress={() =>
                    state.users &&
                    state.users[state.users.length - 1]?.id &&
                    setState((s) => ({
                      ...s,
                      newUser: true,
                      users: [
                        ...s.u,
                        {
                          firstname: "",
                          lastname: "",
                          phonenumber: "",
                          email: "",
                          password: "",
                        },
                      ],
                    }))
                  }
                />
              </View>
              <View
                style={{
                  alignSelf: "center",
                }}
              >
                <Text style={{ color: "white", fontWeight: "500" }}>
                  Neuer Benutzer anlegen
                </Text>
              </View>
            </View>
          </TouchableOpacity>
        </View>
      </ScrollView>
      <FloatingButton onPress={onFabPress} />
    </View>
  );
};
