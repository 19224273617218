import React from "react";
import { View, TouchableOpacity } from "react-native";
import * as Icons from "@expo/vector-icons";

export function Icon(props) {
  let IconTag = Icons[props.tag];
  return (
    <View style={[props.style ? props.style : {}, { alignSelf: "center" }]}>
      <TouchableOpacity onPress={props.onPress ? props.onPress : null}>
        <IconTag
          name={props.name}
          size={props.size ? props.size : 20}
          color={props.color ? props.color : "gray"}
        />
      </TouchableOpacity>
    </View>
  );
}
