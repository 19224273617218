import { getData } from "./asyncStorage";
import { storeData } from "./asyncStorage";
export async function authHeader() {
  // return authorization header with jwt token
  let authantication = await getData("authantication");

  if (authantication && authantication.token) {
    return { Authorization: `Bearer ${authantication.token}` };
  } else {
    return {};
  }
}
