export const theme = {
  fontSize: {
    regular: 14,
    medium: 16,
    big: 20,
    large: 25,
  },
  fontWeight: {
    regular: "400",
    medium: "500",
    bold: "700",
  },
  color: {
    primary: "#FF7B00",//#01A665
    primaryTransparent: "#FF7B0013", //rgba(245, 131, 51, 1) #A5DE6C
    secondary: "#344",//#054752 
    black: "#252525",
    gray: "gray",
    succes: "#5a5",
    yellow:'#eb2',
    error: "#f55",
    edit: "#408EDF",
    delete: "#f55",
  },
  listItem: {
    width: "90%",
    maxWidth: 400,
    padding: 10,
    margin: 10,
  },

  palette: {
    main: {
      background: "#1f0b02",
      text: "#e8e8e8",
      headline: "#989898",
      sub: "#989898",
      transparent: "rgba(0, 0, 0, 0.8)",
      border: "#ffffff2b",
    },
    second: {
      background: "#fff",
      text: "#000",
    },
    third: {
      background: "#1f1f1f",
      color: "#e0e0e0",
      sub: "#989898",
    },
    ci: {
      primary: "#cf4709",
    },
    action: {
      success: "green",
      erfdror: "red",
    },
  },
};
