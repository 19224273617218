import { StyleSheet } from "react-native";
import { theme } from "./theme";

export const classes = StyleSheet.create({
  app: {
    fontSize: theme.fontSize.regular,
  },
  container: {
    flex: 1,
    //backgroundColor: "#f",
    alignItems: "center",
    justifyContent: "center",
  },
  screen: {
    flex: 1,
    //alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    width: "100%",
    padding: "5%",
  },
  card: {
    width: "100%",
    padding: 20,
    backgroundColor: "#fff",
  },
  cardGray: {
    width: "100%",
    padding: 20,
    minHeight: 45,
    justifyContent: "center",
    backgroundColor: "#888",
  },
  listItem: {
    width: "90%",
    maxWidth: 400,
    padding: 10,
    margin: 10,
  },
  button: {
    backgroundColor: theme.color.primary,
    fontSize: theme.fontSize.regular,
    fontWeight: theme.fontWeight.bold,
    justifyContent: "center",
    paddingHorizontal: 10,
    height: 35,
    marginTop: 10,
    marginBottom: 10,
  },
  buttonS: {
    backgroundColor: "#000",
    fontSize: theme.fontSize.regular,
    fontWeight: theme.fontWeight.bold,
    justifyContent: "center",
    paddingHorizontal: 10,
    height: 35,
    marginTop: 10,
    marginBottom: 10,
  },
  buttonText: {
    fontSize: theme.fontSize.regular,
    fontWeight: theme.fontWeight.regular,
    alignSelf: "center",
    color: "#fff",
  },
  textInput: {
    //fontFamily: 'roboto-regular',
    height: 35,
    padding: 10,
    width: "100%",
    fontSize: 14,
    justifyContent:'center',
    //fontWeight: "300",

    borderWidth: 1,
    borderColor: "#ccc",
    backgroundColor: "#fff",
    alignSelf: "center",
    marginTop: 10,
    marginBottom: 10,
    // "&:focus": {
    //   borderWidth: 1,
    //   borderColor: theme.color.primaryTransparent,
    // },
  },
  dropdown: {
    //fontFamily: 'roboto-regular',
    height: 40,
    padding: 10,
    width: "100%",
    fontSize: 14,
    //fontWeight: "300",

    borderWidth: 1,
    borderColor: "#ccc",
    backgroundColor: "#fff",
    alignSelf: "center",
    marginTop: 10,
    marginBottom: 10,
  },
  error: {
    width: "100%",
    color: "#f88",
    alignSelf: "center",
  },
  title1: {
    alignSelf: "flex-start",
    fontSize: theme.fontSize.large,
    fontWeight: theme.fontWeight.bold,
  },
  title2: {
    alignSelf: "flex-start",
    fontSize: theme.fontSize.big,
    fontWeight: theme.fontWeight.bold,
  },
  title3: {
    alignSelf: "flex-start",
    fontSize: theme.fontSize.medium,
    fontWeight: theme.fontWeight.bold,
  },
  separator: {
    borderBottomColor: "#ddd",
    borderBottomWidth: 1,
    //borderBottom: "solid",
  },
  row: {
    flexDirection: "row",
  },
  column: {
    flexDirection: "column",
  },
  checkboxContainer: {
    flexDirection: "row",
  },
  checkbox: {
    alignSelf: "center",
  },
  label: {
    margin: 8,
  },
  ".center-row": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  datePickerStyle: {
    width: "100%",
    marginTop: 20,
  },

  //Icons Styles
  iconSmallGray1: {
    fontSize: 15,
    color: "#c0c0c0",
    margin: 10,
  },
  iconSmallGray2: {
    fontSize: 15,
    color: theme.color.gray,
    margin: 10,
  },
  iconMediumGray1: {
    fontSize: 20,
    color: "#c0c0c0",
    margin: 10,
  },
  iconMediumGray2: {
    fontSize: 20,
    color: theme.color.gray,
    margin: 8,
  },
  iconBigGray1: {
    fontSize: 30,
    color: "#c0c0c0",
    margin: 10,
  },
  iconBigGray2: {
    fontSize: 30,
    color: theme.color.gray,
    margin: 10,
  },
  iconSmallPrimary: {
    fontSize: 15,
    color: theme.color.primary,
    margin: 10,
  },
  iconSmallSecondary: {
    fontSize: 15,
    color: theme.color.secondary,
    margin: 10,
  },
  iconMediumPrimar: {
    fontSize: 20,
    color: theme.color.primary,
    margin: 10,
  },
  iconMediumSecondary: {
    fontSize: 20,
    color: theme.color.secondary,
    margin: 10,
  },
  iconBigPrimar: {
    fontSize: 30,
    color: theme.color.primary,
    margin: 10,
  },
  iconBigSecondary: {
    fontSize: 30,
    color: theme.color.secondary,
    margin: 10,
  },

  iconSmallWhite: {
    fontSize: 15,
    color: "white",
    margin: 10,
  },
  iconMediumWhite: {
    fontSize: 20,
    color: "white",
    margin: 10,
  },

  iconBigWhite: {
    fontSize: 30,
    color: "white",
    margin: 10,
  },
  circle: {
    width: 12,
    height: 12,
    borderRadius: 15 / 2,
  },
});
