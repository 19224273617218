import React, { useState, useRef } from "react";
import {
  StyleSheet,
  View,
  ImageBackground,
  Text,
  TextInput,
  TouchableOpacity,
  ScrollView,
  Image,
  ActivityIndicator,
  TouchableHighlight,
} from "react-native";
import { Button } from "../Utils";
import { classes, theme } from "../helpers";
import { PasswordInput } from "../Utils";
import headerPic from "../assets/images/headerPic.jpg";
import { login } from "../helpers/apis";

export function Start({ reload, navigation }) {
  const [state, setState] = useState({
    email: "",
    password: "",
    firstname: "",
    lastname: "",
    firstnameError: "",
    lastnameError: "",
    emailError: "",
    passwordError: "",
    loading: false,
  });
  const changeEventListener = (value, name) => {
    setState({ ...state, [name]: value, [`${name}Error`]: "" });
  };
  const validate = () => {
    const result =
      state.password != "" && state.firstname != "" && state.lastname != "";
    if (result == false) {
      setState((s) => ({
        ...s,
        passwordError: s.password == "" ? "Passwort erforderlich" : "",
        firstnameError: s.firstname == "" ? "Vorname erforderlich" : "",
        lastnameError: s.lastname == "" ? "Nachname erforderlich" : "",
      }));
    }

    return result;
  };

  const submit = () => {
    if (validate()) {
      login(state, setState, reload);
    }
  };

  const billingLastnameInput = useRef(null);
  const billingPasswordInput = useRef(null);
  return (
    <ScrollView>
      <View>
        <View>
          <Image source={headerPic} style={styles.image} />
        </View>
        <View>
          <View style={styles.LoginFormular}>
            <View
              style={{
                width: "100%",
                alignSelf: "center",
                marginBottom: 15,
              }}
            >
              <Text style={styles.textCenterBig}>Einloggen</Text>
            </View>

            <View style={[classes.card, { paddingBottom: 20 }]}>
              <TextInput
                placeholder="Vorname *"
                textContentType="nickname"
                value={state.firstname}
                name="firstname"
                onChangeText={(e) => changeEventListener(e, "firstname")}
                style={styles.textInput}
                onSubmitEditing={() => {
                  billingLastnameInput.current.focus();
                }}
              ></TextInput>
              {state.firstnameError !== "" && (
                <Text style={{ alignSelf: "left", color: theme.color.error }}>{state.firstnameError}</Text>
              )}
              <TextInput
                ref={billingLastnameInput}
                placeholder="Nachname *"
                textContentType="familyName"
                value={state.lastname}
                name="lastname"
                onChangeText={(e) => changeEventListener(e, "lastname")}
                style={styles.textInput}
                onSubmitEditing={() => {
                  billingPasswordInput.current.focus();
                }}
              ></TextInput>
              {state.lastnameError !== "" && (
                <Text style={{ alignSelf: "left", color: theme.color.error }}>
                  {state.lastnameError}
                </Text>
              )}
              <PasswordInput
                reference={billingPasswordInput}
                value={state.password}
                onChangeText={(e) => changeEventListener(e, "password")}
              ></PasswordInput>
              {state.passwordError !== "" && (
                <Text style={{ alignSelf: "left", color: theme.color.error }}>{state.passwordError}</Text>
              )}
              <Button onPress={submit} style={styles.button}>
                <View style={classes.row}>
                  <Text style={styles.buttonText}>Login</Text>
                  {state.loading && (
                    <ActivityIndicator
                      style={{ alignSelf: "center", marginLeft: 5 }}
                      size="small"
                      color="#fff"
                    />
                  )}
                </View>
              </Button>
            </View>
          </View>
        </View>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    //backgroundColor: "rgba(255,255,255,1)",
    width: "100%",
    height: "100%",
  },
  image1: {
    flex: 1,
  },
  rect2: {
    height: "100%",
    width: "100%",
    //backgroundColor: 'rgba(0,0,0,0.5)',
  },
  LoginFormular: {
    padding: 10,
    //backgroundColor: 'rgb(255,255,255)',
    color: "#1a1a1a",
    marginTop: 20,
    width: "90%",
    maxWidth: 500,
    alignSelf: "center",
  },
  textCenterBig: {
    // fontFamily: 'roboto-300',
    fontWeight: "bold",
    fontSize: 30,
    alignSelf: "flex-start",
  },
  textCenterBig1: {
    // fontFamily: 'roboto-300',
    //fontWeight: "100",
    fontSize: 30,
    alignSelf: "center",
    marginBottom: 20,
  },
  image: {
    width: "100%",
    height: 150,
    resizeMode: "cover",
  },
  textInput: {
    //fontFamily: 'roboto-regular',
    height: 40,
    padding: 10,
    width: "100%",
    fontSize: 14,
    //fontWeight: "300",

    borderWidth: 1,
    borderColor: "#ccc",
    backgroundColor: "#fff",
    alignSelf: "center",
    marginTop: 10,
    marginBottom: 10,
  },
  button: {
    height: 40,
    width: "100%",
    backgroundColor: theme.color.primary,
    marginTop: 10,
    alignSelf: "center",
  },
  textCenterNormal: {
    fontFamily: "roboto-regular",
    color: "rgba(25,25,25,1)",
    fontSize: 15,
    //fontWeight: "300",

    marginTop: 20,
    alignSelf: "center",
  },
  rect1: {
    height: 20,
    backgroundColor: "rgba(165,222,108,1)",
  },
  cupertinoHeaderWithActionButton1: {
    height: 40,
    backgroundColor: "rgba(165,222,108,1)",
  },
  buttonText: {
    color: "#fff",
    fontSize: 15,
    fontWeight: "700",
    //fontFamily: 'roboto-700',
  },
  toBottom: {
    position: "absolute",
    alignSelf: "center",
    bottom: 0,
    padding: 20,
  },
  error: {
    width: "100%",
    color: "#f88",
    maxWidth: 400,
    alignSelf: "center",
  },
});
