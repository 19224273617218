import React, { Component } from 'react';
import { StyleSheet, TouchableOpacity, Text } from 'react-native';

export function Button(props) {
    const border = props.border ? styles.border : {}
    return (
        <TouchableOpacity
            onPress={props.onPress && props.onPress}
            style={[styles.container, props.style, border]}
        >
            {props.leftIcon && (
                <Icon
                    tag={props.rightIcon.tag}
                    name={props.rightIcon.name}
                    style={props.rightIcon.style}
                />
            )}
            <Text>{props.children}</Text>
            {props.rightIcon && (
                <Icon
                    tag={props.rightIcon.tag}
                    name={props.rightIcon.name}
                    style={props.rightIcon.style}
                />
            )}
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        paddingLeft: 15,
        paddingRight: 15,
        height:40
    },
    border: {
      borderWidth: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }
});