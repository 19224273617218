import React, { useEffect, useRef, useState } from "react";

import {
  ActivityIndicator,
  Alert,
  Button,
  FlatList,
  Platform,
  RefreshControl,
  ScrollView,
  Text,
  TouchableHighlight,
  TouchableOpacity,
  View,
} from "react-native";
import { classes, deleteActions, getActions } from "../helpers";
import { FloatingButton, Icon } from "../Utils";
import SkeletonContent from "react-native-skeleton-content";
import * as Animatable from "react-native-animatable";

const wait = (timeout) => {
  return new Promise((resolve) => setTimeout(resolve, timeout));
};

export const NewPersonal = ({ route, user, role, navigation }) => {
  const [state, setState] = useState({
    user,
    role,
  });

  const onRefresh = React.useCallback(() => {
    getActions(state, setState);

    //wait(2000).then(() => setRefreshing(false));
  }, []);

  useEffect(() => {
    //getActions(state, setState);
  }, []);

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      getActions(state, setState);
    });

    return unsubscribe;
  }, [navigation]);
  const scrollRefPLAction = useRef();

  const onFabPress = () => {
    scrollRefPLAction.current?.scrollTo({
      y: 0,
      animated: true,
    });
  };
  const Action = ({ item, index }) => (
    <TouchableOpacity
      onPress={() =>
        role == 1
          ? navigation.navigate("SingleAction", {
              action: item,
              title: (
                <View>
                  <Text
                    style={{ fontSize: 18, fontWeight: "bold", color: "#fff" }}
                  >
                    {item.name}
                  </Text>
                  <Text style={{ color: "#fff" }}>
                    {new Date(item.startDate).toLocaleDateString()}
                    {" - "}
                    {new Date(item.endDate).toLocaleDateString()}
                  </Text>
                </View>
              ),
            })
          : navigation.navigate("PLStores", { action: item })
      }
      style={{ marginBottom: "5%" }}
    >
      <View style={[classes.card, { paddingBottom: 10, paddingTop: 10 }]}>
        <Text style={{ fontWeight: "700" }}>{item.name}</Text>
        <Text>
          {new Date(item.startDate).toLocaleDateString()}
          {" - "}
          {new Date(item.endDate).toLocaleDateString()}
        </Text>
        {item.description?.length > 0 && (
          <View>
            <Text numberOfLines={role == 1 ? 2 : 100} ellipsizeMode="tail">
              {item.description}
            </Text>
          </View>
        )}
        {role == 1 && (
          <View style={classes.row}>
            <View style={{ paddingRight: 20 }}>
              <TouchableOpacity
                onPress={() =>
                  navigation.navigate("PLStores", { action: item })
                }
                style={classes.button}
              >
                <Text style={classes.buttonText}>REPORTING</Text>
              </TouchableOpacity>
            </View>
            <View>
              <TouchableOpacity
                onPress={() => {
                  let confirm = window.confirm(
                    "Wollen Sie wirklich diese Aktion löchen?"
                  );
                  if (confirm)
                    deleteActions({ ...state, id: item.id }, setState);
                }}
                style={classes.buttonS}
              >
                <View style={classes.row}>
                  <Text style={classes.buttonText}>LÖSCHEN</Text>
                  {item.deleting && (
                    <ActivityIndicator
                      style={{ alignSelf: "center", marginLeft: 5 }}
                      size="small"
                      color="#fff"
                    />
                  )}
                </View>
              </TouchableOpacity>
            </View>
          </View>
        )}
        {item.error?.length > 0 && (
          <View>
            <Text style={classes.error}>{item.error}</Text>
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
  return (
    <View style={{ flex: 1 }}>
      <ScrollView
        ref={scrollRefPLAction}
        refreshControl={
          <RefreshControl refreshing={state.loading} onRefresh={onRefresh} />
        }
      >
        <View style={[classes.screen, { maxWidth: 700 }]}>
          {state.actions?.length >= 0 ? (
            <Animatable.View id="aview1" easing="ease-in" animation="fadeIn">
              {state.actions.map((item, index) => (
                <View key={item.id}>
                  <Action item={item} index={index} />
                </View>
              ))}
            </Animatable.View>
          ) : (
            <Animatable.View
              id="aview2"
              easing="ease-in-out"
              duration={500}
              animation="fadeIn"
            >
              <View>
                <SkeletonContent
                  containerStyle={{ flex: 1 }}
                  isLoading={true}
                  layout={[
                    {
                      key: "skeletonItem1",
                      width: "100%",
                      height: 132,
                      marginBottom: "5%",
                      borderRadius: 0,
                    },
                    {
                      key: "skeletonItem12",
                      width: "100%",
                      height: 132,
                      marginBottom: "5%",
                      borderRadius: 0,
                    },
                  ]}
                ></SkeletonContent>
              </View>
            </Animatable.View>
          )}

          {role == 1 && (
            <TouchableHighlight
              onPress={() => navigation.navigate("NewAction")}
              style={[
                { marginBottom: "5%", backgroundColor: "#888", padding: 10 },
              ]}
            >
              <View style={classes.row}>
                <View style={{ marginRight: 10 }}>
                  <Icon
                    tag="Entypo"
                    name="plus"
                    size={30}
                    color="#fff"
                    onPress={() => navigation.navigate("NewAction")}
                  />
                </View>
                <View
                  style={{
                    alignSelf: "center",
                  }}
                >
                  <Text style={{ color: "white", fontWeight: "500" }}>
                    Neu Aktion anlegen
                  </Text>
                </View>
              </View>
            </TouchableHighlight>
          )}
        </View>
      </ScrollView>
      <FloatingButton onPress={onFabPress} />
    </View>
  );
};
