import Axios from "axios";
import { showMessage } from "react-native-flash-message";
import { authHeader } from "./authHeader";
import { proxy } from "./proxy";
const axios = Axios;

export const usersApi = {
  get,
  getById,
  delete: _delete,
  update,
  create,
};

async function get(state, setState) {
  setState((s) => ({ ...s, loading: true }));
  await axios
    .get(`${proxy}/users`, {
      headers: await authHeader(),
    })
    .then((resp) => {
      setState((s) => ({
        ...s,
        count: resp.data.count,
        constUsers: resp.data.rows,
        users: resp.data.rows,
        loading: false,
      }));
    })
    .catch((error) => {
      setState((s) => ({
        ...s,
        error: "Keine Verbindung möglich!",
        loading: false,
      }));
    });
}
async function getById(state, setState) {
  setState((s) => ({ ...s, loading: true }));
  await axios
    .get(`${proxy}/users/${state.id}`, {
      headers: await authHeader(),
    })
    .then((resp) => {
      setState((s) => ({
        ...s,
        ...resp.data,
        loading: false,
      }));
    })
    .catch((error) => {
      setState((s) => ({
        ...s,
        error: "Keine Verbindung möglich!",
        loading: false,
      }));
    });
}
async function _delete(state, setState) {
  setState((s) => ({
    ...s,
    users: s.users.map((a) =>
      a.id == state.id ? { ...a, deleting: true, error: "" } : a
    ),
  }));
  await axios
    .delete(`${proxy}/users/${state.id}`, {
      headers: await authHeader(),
    })
    .then((resp) => {
      setState((s) => ({
        ...s,
        count: s.count - 1,
        users: s.users.filter((a) => a.id != state.id),
      }));
    })
    .catch((error) => {
      setState((s) => ({
        ...s,
        error: "Keine Verbindung möglich!",
        users: s.users.map((a) =>
          a.id == state.id
            ? { ...a, deleting: false, error: "Fehler beim Löschen" }
            : a
        ),
      }));
    });
}
async function update(user, state, setState, succesAction, errorAction) {
  setState((s) => ({
    ...s,
    users: s.users.map((u) => (u.id == user.id ? { ...u, loading: true } : u)),
  }));
  await axios
    .patch(`${proxy}/users`, user, {
      headers: await authHeader(),
    })
    .then((resp) => {
      setState((s) => ({
        ...s,
        users: s.users.map((u) =>
          u.id == user.id ? { ...u, loading: false, edit: false } : u
        ),
      }));
      showMessage({
        message: "Gespeichert!",
        description: "Benutzer erfolgreich gespeichert",
        type: "success",
        icon: "success",
        floating: true,
      });
      succesAction && succesAction();
    })
    .catch((error) => {
      setState((s) => ({
        ...s,
        users: s.users.map((u) =>
          u.id == user.id
            ? { ...u, error: "Keine Verbindung möglich!", loading: false }
            : u
        ),
      }));
      showMessage({
        message: "Aktion nicht möglich",
        description:
          error.response?.status == 401
            ? `Sie haben keine Berechtigung`
            : error.response?.status == 404
            ? "Fehler wärhend der Verbindung"
            : "Fehler wärhend der Verbindung",
        type: "warning",
        icon: "warning",
        floating: true,
        duration: 10000,
      });
      errorAction && errorAction();
    });
}

async function create(state, setState, succesAction, errorAction) {
  setState((s) => ({
    ...s,
    users: s.users.map((u) => (!u.id ? { ...u, loading: true } : u)),
  }));
  await axios
    .post(`${proxy}/signup`, state.users.filter((u) => !u.id)[0], {
      headers: await authHeader(),
    })
    .then((resp) => {
      setState((s) => ({
        ...s,
        users: s.users.map((u) =>
          !u.id
            ? {
                ...u,
                stores: [],
                ...resp.data.user,
                loading: false,
                edit: false,
              }
            : u
        ),
      }));
      showMessage({
        message: "Gespeichert!",
        description: "Benutzer erfolgreich gespeichert",
        type: "success",
        icon: "success",
        floating: true,
      });
      succesAction && succesAction();
    })
    .catch((error) => {
      setState((s) => ({
        ...s,
        users: s.users.map((u) =>
          !u.id
            ? { ...u, error: "Keine Verbindung möglich!", loading: false }
            : u
        ),
      }));
      showMessage({
        message: "Aktion nicht möglich",
        description:
          error.response?.status == 401
            ? `Sie haben keine Berechtigung`
            : error.response?.status == 404
            ? "Fehler wärhend der Verbindung"
            : "Fehler wärhend der Verbindung",
        type: "warning",
        icon: "warning",
        floating: true,
        duration: 10000,
      });
      errorAction && errorAction();
    });
}
