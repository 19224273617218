import React, { useState } from "react";
import { View, TextInput, StyleSheet } from "react-native";
import { Icon } from "./Icon";
import { classes } from "../helpers";
export const PasswordInput = (props) => {
  const [state, setState] = useState({
    showPassword: true,
  });
  const toggleSwitch = () => {
    setState({ showPassword: !state.showPassword });
  };
  return (
    <View style={styles.container}>
      <TextInput
        style={styles.input}
        ref={props.reference}
        value={props.value}
        autoFocus={props.autoFocus}
        placeholder={"Passwort *"}
        secureTextEntry={state.showPassword}
        onChangeText={(password) => {
          setState({ ...state, password });
          props.onChangeText(password);
        }}
        name="password"
      />
      <Icon
        onPress={toggleSwitch}
        tag="Feather"
        name={state.showPassword == false ? "eye" : "eye-off"}
        style={classes.iconMediumGray2}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    width: "100%",
    marginTop: 10,
    marginBottom: 10,
    alignSelf: "center",
    borderWidth: 1,
    borderColor: "#ccc",
    backgroundColor: "#fff",
    height: 40,
  },
  input: {
    flex: 1,
    height: 40,
    padding: 10,
    fontWeight: "300",
  },
});
