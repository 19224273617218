import React from "react";
import { TouchableOpacity } from "react-native";
import { View } from "react-native-animatable";

import { theme } from "../helpers";
import { Icon } from "./Icon";

export const FloatingButton = (props) => {
  const { onPress } = props;
  return (
    <TouchableOpacity
      style={{
        backgroundColor: theme.color.primary,
        position: "absolute",
        justifyContent: "center",
        right: 10,
        bottom: 10,
        width: 40,
        height: 40,
      }}
      onPress={onPress}
    >
      <Icon
        onPress={onPress}
        tag="Entypo"
        name="arrow-up"
        color={'#fff'}
      />
    </TouchableOpacity>
  );
};
