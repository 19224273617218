import React from "react";

import { Text, View } from "react-native";

export const ADMStores = ({ navigation }) => {
  return (
    <View>
      <Text>ADMStores</Text>
    </View>
  );
};
