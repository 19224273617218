import React, { useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import Modal from "react-native-modal";
import { Icon } from "./Icon";
import { classes } from "../helpers";
import { Ionicons } from "@expo/vector-icons";

export function FilterModal(props) {
  const [isModalVisible, setModalVisible] = useState(false);
  const [state, setState] = useState({
    filter: null,
    order: null,
  });

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  return (
    <View style={{ justifyContent: "center" }}>
      <TouchableOpacity
        onPress={toggleModal}
        style={{
          padding: 5,
          paddingLeft: 10,
          paddingRight: 10,
          //height: 35,
          alignSelf: "center",
          justifyContent: "center",
        }}
      >
        <Ionicons name="filter" size={24} color="gray" />
      </TouchableOpacity>
      <Modal
        isVisible={isModalVisible}
        onBackdropPress={() => setModalVisible(false)}
        onSwipeComplete={() => setModalVisible(false)}
        swipeDirection="down"
        style={{ flex: 1 }}
      >
        <View
          style={[
            classes.card,
            {
              borderRadius: 10,
              width: "100%",
              position: "absolute",
              left: 0,
              bottom: 0,
            },
          ]}
        >
          <View>
            <Text style={[classes.title3, { padding: 10 }]}>Filtern</Text>
            {props.filters.map((item) => (
              <TouchableOpacity
                key={item.name}
                style={{
                  padding: 10,
                  borderBottomWidth: 1,
                  borderBottom: "solid",
                  borderBottomColor: "#fefefe",
                  backgroundColor:
                    item.name == state.filter?.name ? "#eee" : "#fff",
                }}
                onPress={() => {
                  setState((s) => ({ ...s, filter: item }));
                }}
              >
                <Text style={{ fontWeight: "500" }}>{item.name}</Text>
              </TouchableOpacity>
            ))}
          </View>
          <View>
            <Text style={[classes.title3, { padding: 10, marginTop: 30 }]}>
              Sortieren
            </Text>
            {props.orders.map((item) => (
              <TouchableOpacity
                key={item.name}
                style={{
                  padding: 10,
                  borderBottomWidth: 1,
                  borderBottom: "solid",
                  borderBottomColor: "#fefefe",
                  backgroundColor:
                    item.name == state.order?.name ? "#eee" : "#fff",
                }}
                onPress={() => {
                  setState((s) => ({ ...s, order: item }));
                }}
              >
                <Text style={{ fontWeight: "500" }}>{item.name}</Text>
              </TouchableOpacity>
            ))}
          </View>
          <View style={[classes.row, {marginTop:20}]}>
            <View>
              <TouchableOpacity
                style={classes.buttonS}
                onPress={() => {
                  setState({})
                  props.setState((s) => ({
                    ...s,
                    filteredStores: s.stores,
                  }));
                  setModalVisible(false);
                }}
              >
                <Text style={{ color: "#fff" }}>FILTER LÖSCHEN</Text>
              </TouchableOpacity>
            </View>
            <View style={{ marginLeft: 15 }}>
              <TouchableOpacity
                style={classes.button}
                onPress={() => {
                  props.setState((s) => ({
                    ...s,
                    filteredStores: s.stores
                      .sort(state.order ? state.order.action : () => true)
                      .filter(state.filter ? state.filter.action : () => true),
                  }));
                  setModalVisible(false);
                }}
              >
                <Text style={{ color: "#fff" }}>SPEICHERN</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}
